import moment from 'moment';
import { APP_EXPRESS_TOGGLE, APP_PROFILE_SETUP_TOGGLE } from '../actions/app';
import {
  STATE_REGISTERING,
  STATE_WELCOME,
  STATE_APP,
  SW_UPDATE,
  SW_UPDATE_FINISHED,
  SW_INIT,
  HEART_BEAT_RETRIEVED,
  SET_FROM_URL,
  LOGIN_SUCCESSFULL,
  LOGOUT_REQUESTED
} from '../actions/types';

const defaultState = {
  state: STATE_REGISTERING,
  swUpdate: false,
  swReg: null,
  maintainance: false,
  fromURL: '',
  // Dialog to use for the express registration and login.
  expressDialog: false,
  fromAction: null,
  profileSetupBanner: false,
  profileSetupBannerLastClose: null
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case STATE_REGISTERING:
      return {
        ...state,
        state: STATE_REGISTERING
      };
    case STATE_WELCOME:
      return {
        ...state,
        state: STATE_WELCOME
      };
    case STATE_APP:
      return {
        ...state,
        state: STATE_APP
      };
    case SW_UPDATE:
      return {
        ...state,
        swUpdate: true,
        swReg: action.payload
      };
    case SW_UPDATE_FINISHED:
      return {
        ...state,
        swUpdate: false
      };
    case SW_INIT:
      return {
        ...state,
        swReg: action.payload
      };
    case HEART_BEAT_RETRIEVED:
      return {
        ...state,
        maintainance: action.heartbeat['maintainance-web']
      };
    case SET_FROM_URL:
      return {
        ...state,
        fromURL: action.url
      };
    case APP_EXPRESS_TOGGLE:
      return {
        ...state,
        expressDialog: action.payload?.open,
        fromAction: action.payload?.open
          ? action.payload?.fromAction
          : state.fromAction
      };
    case LOGIN_SUCCESSFULL:
    case LOGOUT_REQUESTED:
      return {
        ...state,
        expressDialog: false,
        profileSetupBanner: false,
        profileSetupBannerLastClose: null
      };
    case APP_PROFILE_SETUP_TOGGLE:
      let lastClose = state.profileSetupBannerLastClose;
      if (!action.open && action.manual) {
        lastClose = moment();
      }
      return {
        ...state,
        profileSetupBanner: action.open,
        profileSetupBannerLastClose: lastClose
      };
    default:
      return state;
  }
};
