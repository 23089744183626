import React from 'react';
import { useMediaQuery, useTheme } from '@material-ui/core';

const withIsMobile = (WrappedComponent, size) => {
  return props => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down(size || 'md'));

    return <WrappedComponent {...props} isMobile={isMobile} />;
  };
};

export default withIsMobile;
