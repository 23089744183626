import { makeStyles, SvgIcon, Typography } from '@material-ui/core';
import React from 'react';
import useIsMobile from '../hooks/useIsMobile';
import { NavLink, useLocation } from 'react-router-dom/cjs/react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as CloseIcon } from '../../images/Icons/x-circle.svg';
import { profileSetupToggle } from '../../redux/actions/app';

const ProfileSetupBanner = () => {
  const user = useSelector(state => state.auth.user);
  const isMobile = useIsMobile();
  const classes = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();

  const LOCATIONS = ['/home', '/producto', '/vestidor'];

  const onClose = () => {
    dispatch(profileSetupToggle(false, true));
  };

  React.useEffect(() => {
    if (LOCATIONS.find(l => location.pathname.includes(l))) {
      dispatch(profileSetupToggle(true));
    } else {
      dispatch(profileSetupToggle(false));
    }
  }, [location, dispatch, LOCATIONS]);

  if (!user) {
    return null;
  }

  if (isMobile) {
    return (
      <div className={classes.root}>
        <Typography
          component={NavLink}
          to={`/vestidor/${user.username}/sobre-mi`}
          className={classes.link}
          style={{ fontSize: '0.75rem', margin: 0 }}
        >
          Configurá tu perfil para una mejor selección de productos!
        </Typography>
        <SvgIcon onClick={onClose} style={{ margin: '0 0 0 4px' }}>
          <CloseIcon />
        </SvgIcon>
      </div>
    );
  }
  return (
    <div className={classes.root}>
      <Typography className={classes.title}>
        Terminá de configurar tu perfil!
      </Typography>
      <Typography className={classes.subtitle}>
        Contanos más de vos así podemos mostrarte productos más personalizados!
      </Typography>
      <Typography
        component={NavLink}
        to={`/vestidor/${user.username}/sobre-mi`}
        className={classes.link}
      >
        Configurar
      </Typography>
      <SvgIcon className={classes.closeIcon} onClick={onClose}>
        <CloseIcon />
      </SvgIcon>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    position: 'fixed',
    top: 0,
    zIndex: 999999,
    backgroundColor: theme.palette.yellow.background,
    color: '#000',
    height: '42px',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& > *': {
      margin: theme.spacing(0, 2)
    }
  },
  title: {
    fontWeight: 500,
    color: '#000'
  },
  subtitle: {
    color: '#000'
  },
  link: {
    fontWeight: 500,
    color: '#000',
    textDecoration: 'underline'
  },
  closeIcon: {
    position: 'absolute',
    right: theme.spacing(1),
    cursor: 'pointer'
  }
}));

export default ProfileSetupBanner;
