import { MenuItem, TextField, Typography, makeStyles } from '@material-ui/core';
import React, { useState } from 'react';
import RTVDialog from './dialogs/RTVDialog';

import AdornedButton from './AdornedButton';
import RTVSubCategoriesSelect from './inputs/RTVSubCategoriesSelect';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { transformProfilesData } from './transformProfilesData';
import UserRestClient from '../http/client/Users';
import { closePersonalInfoPopUp } from '../redux/actions/auth';
import DateInput from './DateInput';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  datePicker: {
    height: '59px',
    borderRadius: '15px',
    border: `1px solid ${theme.palette.grey[400]}`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'space-evenly',
    padding: 10,
    margin: 0
  },
  select: {
    fontSize: '19px',
    '&:focus': {
      borderRadius: theme.shape.borderRadius
    }
  },
  personalSelectionContainer: {
    display: 'flex',
    flexFlow: 'column',
    height: '350px',
    justifyContent: 'space-around',
    padding: '20px'
  },
  button: {
    margin: theme.spacing(2, 1, 2, 1)
  },
  label: {
    fontWeight: 500
  },
  interestButton: {
    backgroundColor: '#ebebeb',
    margin: theme.spacing(1),
    color: '#707070',
    fontWeight: 400,
    '& > *': {
      fontSize: '0.9rem'
    }
  },
  subCategoriesList: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap'
  },
  personalInfoLabel: {
    fontWeight: 500
  }
}));

/**
 * @deprecated This component is deprecated and login in AppRouting should be removed
 */
const RTVPersonalInterestModal = ({ isOpen }) => {
  const GENDERS = [
    { value: 'male', name: 'Masculino' },
    { value: 'female', name: 'Femenino' },
    { value: 'dont_tell', name: 'Prefiero no decir' }
  ];

  const dispatch = useDispatch();

  const classes = useStyles();
  const options = useSelector(state => state.categories.categories);

  const [selectedDate, setSelectedDate] = React.useState(
    moment().startOf('day')
  );
  const [loading, setIsLoading] = useState(false);
  const [interestedIn, setInterestedIn] = useState([]);
  const [selectedGender, setSelectedGender] = useState(null);
  const [onOpen, setOnOpen] = useState(true);

  const handleDateChange = event => {
    const date = moment(event.target.value, 'DD/MM/YYYY');
    if (date.isValid() && event.target.value.length === 10) {
      setSelectedDate(date);
    } else {
      setSelectedDate(null);
    }
  };

  const handleSubmit = () => {
    setIsLoading(true);
    const payload = transformProfilesData(
      interestedIn.filter(interest => !interest.sub_categories),
      null,
      null,
      null,
      'SubCategory',
      false
    );
    UserRestClient.createUserProfiles(payload).then(response => {
      if (response.status === 200) {
        UserRestClient.updateUser({
          gender: selectedGender,
          birthDate: selectedDate
        }).then(resp => {
          if (resp.status === 200) {
            setIsLoading(false);
            setOnOpen(false);
          }
        });
      }
    });
  };

  const handleChange = (newValue, optionName) => {
    if (optionName === 'interestedIn') {
      const updatedValues = newValue.filter(value => {
        // Check if the value is a main option or a sub-option
        if (!value.sub_categories) {
          return true; // Main option is always selected
        }
        // Check if any sub-option is selected
        return value.sub_categories.some(subOption =>
          newValue.some(selectedValue => selectedValue.id === subOption.id)
        );
      });
      setInterestedIn(updatedValues);
      return;
    }
    if (optionName === 'gender') {
      setSelectedGender(newValue);
    }
    if (optionName === 'birthDate') {
      setSelectedDate(newValue);
    }
  };

  const onSecondaryAction = () => {
    setOnOpen(false);
    dispatch(closePersonalInfoPopUp());
  };

  React.useEffect(() => {
    handleChange(selectedDate, 'birthDate');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDate]);

  return (
    <RTVDialog
      open={isOpen && onOpen}
      onClose={() => onSecondaryAction()}
      title="Queremos saber un poco más de vos! 😉"
      content={
        <>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <Typography>
              Para poder mostrarte productos de manera más personalizada,
              necesitamos que nos cuentes un poco más de vos!
            </Typography>
          </div>
        </>
      }
      actionSection={
        <div>
          <div className={classes.personalSelectionContainer}>
            <Typography className={classes.label}>Género</Typography>
            <TextField
              variant="outlined"
              value={selectedGender}
              onChange={({ target: { value } }) => {
                handleChange(value, 'gender');
              }}
              SelectProps={{
                classes: {
                  root: classes.select
                }
              }}
              select
            >
              {GENDERS.map(gender => (
                <MenuItem value={gender.value} key={gender.value}>
                  {gender.name}
                </MenuItem>
              ))}
            </TextField>
            <Typography className={classes.tagTypeTitle}>
              Fecha de nacimiento
            </Typography>
            <TextField
              variant="outlined"
              InputProps={{ inputComponent: DateInput }}
              placeholder="DD/MM/AAAA"
              onChange={handleDateChange}
            />
            <Typography className={classes.label}>
              ¿Qué categorías te interesaría ver?
            </Typography>
            <RTVSubCategoriesSelect
              placeholder="Las categorías que te interesan"
              options={options}
              icon={icon}
              checkedIcon={checkedIcon}
              values={interestedIn}
              handleChange={handleChange}
            />
          </div>
          <AdornedButton
            variant="contained"
            color="secondary"
            disabled={loading}
            onClick={onSecondaryAction}
            className={classes.button}
          >
            {'COMPLETAR MÁS TARDE'}
          </AdornedButton>
          <AdornedButton
            variant="contained"
            color="primary"
            loading={loading}
            disabled={loading}
            onClick={handleSubmit}
            className={classes.button}
          >
            GUARDAR
          </AdornedButton>
          <Typography
            color="primary"
            style={{
              fontWeight: 700,
              marginTop: '8px'
            }}
          >
            No dejes de contarnos qué te gusta, así te mostramos productos más
            personaliazdos!
          </Typography>
        </div>
      }
    />
  );
};

export default RTVPersonalInterestModal;
