import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import SvgIcon from '@material-ui/core/SvgIcon';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import withWidth from '@material-ui/core/withWidth';

import { ReactComponent as CloseIcon } from '../../images/Icons/x-circle.svg';
import { ArrowBack } from '@material-ui/icons';

const useStyles = props =>
  makeStyles(theme => ({
    root: {
      padding: theme.spacing(4),
      position: 'relative',
      display: 'flex',
      flexFlow: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      '& > *': {
        margin: theme.spacing(1)
      }
    },
    title: {
      fontWeight: 600,
      fontSize: '1.1rem',
      textAlign: 'center',
      color: props.isRTVPro
        ? theme.palette.green.dark
        : theme.palette.primary.main
    },
    message: {
      fontWeight: 400,
      fontSize: '0.9rem',
      color: '#707070'
    },
    closeIcon: {
      position: 'absolute',
      right: theme.spacing(0.5),
      top: theme.spacing(0.5),
      transform: `translate(-${theme.spacing(0.5)}px, -${theme.spacing(
        0.5
      )}px})`,
      cursor: 'pointer',
      color: '#707070',
      transition: 'all 1s ease-out',
      '&:hover': {
        color: '#000'
      }
    },
    backArrow: {
      position: 'absolute',
      left: theme.spacing(0.5),
      top: theme.spacing(0.5),
      cursor: 'pointer',
      color: '#707070',
      transition: 'all 1s ease-out',
      '&:hover': {
        color: '#000'
      }
    },
    actionsSection: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      '& > *': {
        flexGrow: 1,
        textAlign: 'center',
        width: '100%',
        margin: theme.spacing(0.5)
      },
      '& > button': {
        minWidth: '175px'
      }
    }
  }));

const RTVDialog = ({
  dialogProps,
  title,
  message,
  extra,
  actionSection,
  content,
  noCloseIcon = false,
  noTitle = false,
  width,
  open,
  onClose,
  titleStyles,
  rootStyles,
  classes,
  noActionSection = false,
  backIcon = false,
  onBackIcon,
  isRTVPro,
  messageStyle
}) => {
  classes = useStyles({ isRTVPro })();
  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullScreen={width === 'xs' || width === 'sm'}
      {...dialogProps}
    >
      <div className={classes.root} style={rootStyles || {}}>
        {!noCloseIcon && (
          <SvgIcon className={classes.closeIcon} onClick={onClose}>
            <CloseIcon />
          </SvgIcon>
        )}
        {backIcon && (
          <SvgIcon onClick={onBackIcon} className={classes.backArrow}>
            <ArrowBack />
          </SvgIcon>
        )}
        {!noTitle && (
          <Typography color="primary" className={titleStyles || classes.title}>
            {title}
          </Typography>
        )}
        {content !== undefined && content}
        {message !== undefined && (
          <Typography className={messageStyle || classes.message}>
            {message}
          </Typography>
        )}
        {!!extra && typeof extra === 'string' ? (
          <Typography className={classes.message}>{extra}</Typography>
        ) : (
          extra
        )}
        {!noActionSection && (
          <div className={classes.actionsSection}>{actionSection}</div>
        )}
      </div>
    </Dialog>
  );
};

export default withWidth()(RTVDialog);
