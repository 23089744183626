import React from 'react';
import { Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { removeToast } from '../../redux/actions/settings';
import withIsMobile from '../hoc/withIsMobile';

const useStyles = makeStyles(theme => ({
  '@keyframes slideInOut': {
    '0%': {
      opacity: 0,
      transform: 'translateX(100%)'
    },
    '10%, 90%': {
      opacity: 1,
      transform: 'translateX(0)'
    },
    '100%': {
      opacity: 0,
      transform: 'translateX(100%)'
    }
  },
  toast: {
    position: 'fixed',
    top: '13%',
    padding: '10px 10px',
    borderRadius: '5px',
    fontSize: '16px',
    zIndex: 1000,
    opacity: 0,
    transform: 'translateX(100%)',
    animation: '$slideInOut 4s ease-in-out forwards',
    '@media (min-width: 426px)': {
      right: '20px',
      minWidth: '375px'
    },
    '@media (max-width: 425px)': {
      top: '9.5%',
      left: '5px',
      right: '5px'
    },
    '@media (max-width: 370px)': {
      fontSize: '14px'
    }
  },
  toast_warning: {
    backgroundColor: '#F8F2C4'
  },
  message: {
    fontSize: '16px',
    '@media (max-width: 370px)': {
      fontSize: '14px'
    },
    textAlign: 'center',
    lineHeight: '18px'
  },
  content: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center'
  },
  button: {
    border: 0,
    backgroundColor: 'transparent',
    marginLeft: '5px'
  },
  text_button_warning: {
    color: 'black'
  }
}));

const Toast = () => {
  const dispatch = useDispatch();
  const toastQueue = useSelector(state => state.settings.toastQueue);
  const [currentToast, setCurrentToast] = React.useState(null);
  const classes = useStyles({ duration: currentToast?.duration });

  React.useEffect(() => {
    if (toastQueue.length > 0 && !currentToast) {
      setCurrentToast(toastQueue[0]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toastQueue, currentToast]);

  React.useEffect(() => {
    let timer;

    if (currentToast) {
      if (toastQueue.length > 1) {
        timer = setTimeout(async () => {
          await dispatch(removeToast());
          await setCurrentToast(null);
        }, 1000);
      } else {
        timer = setTimeout(async () => {
          await dispatch(removeToast());
          await setCurrentToast(null);
        }, currentToast.duration || 4000);
      }
    }

    return () => timer && clearTimeout(timer);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentToast, dispatch]);

  if (!currentToast) return null;

  return (
    <div
      className={`${classes.toast} ${
        classes[`toast_${currentToast?.type || ''}`]
      }`}
      style={{
        animation: `$slideInOut ${
          currentToast.duration || 4000
        }ms ease-in-out forwards`
      }}
    >
      <div className={classes.content}>
        <Typography
          className={`${classes.message} ${
            classes[`text_button_${currentToast?.type || ''}`]
          }`}
        >
          {currentToast?.message}
        </Typography>
      </div>
    </div>
  );
};

export default withIsMobile(Toast, 'xs');
