import {
  LOGIN_ERRORED,
  LOGIN_REQUESTED,
  LOGIN_SUCCESSFULL,
  LOGIN_FACEBOOK_REQUESTED,
  LOGIN_FACEBOOK_SUCCESSFULL,
  LOGIN_FACEBOOK_ERRORED,
  REGISTER_REQUESTED,
  REGISTER_SUCCESSFULL,
  REGISTER_ERRORED,
  CHANGE_EMAIL_REQUESTED,
  CHANGE_EMAIL_SUCCESSFULL,
  CHANGE_EMAIL_ERRORED,
  PIN_VERIFY_SUCCESSFULL,
  CHANGE_PHONE_REQUESTED,
  CHANGE_PHONE_SUCCESSFULL,
  CHANGE_PHONE_ERRORED,
  UPDATE_USER_SUCCESSFULL,
  UPDATE_USER_ERRORED,
  UPDATE_USER_REQUESTED,
  UPDATE_USER_MEDIA_REQUESTED,
  UPDATE_USER_MEDIA_SUCCESSFULL,
  UPDATE_USER_MEDIA_ERRORED,
  IDENTIFIER_VERIFICATION_REQUESTED,
  IDENTIFIER_VERIFICATION_SUCCESSFULL,
  IDENTIFIER_VERIFICATION_ERRORED,
  IDENTIFIER_RESEND_REQUESTED,
  IDENTIFIER_RESEND_SUCCESSFULL,
  IDENTIFIER_RESEND_ERRORED,
  VALIDATE_SHOWROOM,
  CHECK_FACEBOOK_REQUESTED,
  CHECK_FACEBOOK_DUPLICATED,
  CHECK_FACEBOOK_ERRORED,
  LOGOUT_REQUESTED,
  PIN_OPTIONS_REQUESTED,
  PIN_OPTIONS_RETRIEVED,
  PIN_OPTIONS_FAILED,
  PIN_LOGIN_REQUESTED,
  PIN_LOGIN_SUCCESSFULL,
  PIN_LOGIN_ERRORED,
  LOGIN_PIN_REQUESTED,
  LOGIN_PIN_SUCCESSFULL,
  LOGIN_PIN_ERRORED,
  CANCEL_REGISTRATION_SUCCESSFULL,
  UPDATE_USER_PASSWORD_SUCCESSFULL,
  LOGIN_DISABLED_SUCCESSFULL,
  ENABLE_USER_CANCELLED,
  ENABLE_USER_SUCCESSFULL,
  PENDING_ID_STORED,
  PIN_VOICE_CALL_REQUESTED,
  PIN_VOICE_CALL_SUCCESSFUL,
  PIN_VOICE_CALL_FAILED,
  LOGIN_GOOGLE_REQUESTED,
  LOGIN_GOOGLE_SUCCESSFULL,
  LOGIN_GOOGLE_ERRORED,
  PERSONAL_INFO_POPUP_CLOSED,
  CHECK_MERCADOPAGO_ERRORED,
  CHECK_MERCADOPAGO_REQUESTED,
  CHECK_MERCADOPAGO_SUCCESSFULL,
  DOWNLOAD_APP_BANNER_CLOSED,
  UPDATE_BY_PRIVATE_STATS
} from '../actions/types';

import Reactotron from '../../reactotronConfig';
import moment from 'moment';

const defaultState = {
  status: null,
  isLoggedIn: false,
  showroom: false,
  roles: [],
  token: null,
  user: null,
  userChanges: [],
  error: null,
  loading: false,
  identifierType: null,
  pinOptions: null,
  jwtError: false,
  resoveringPassword: false,
  lastPINRequested: null,
  lastCallRequested: null,
  disabledUser: false,
  pendingReviewId: null,
  downloadAppBannerExpireDate: null
};

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case LOGOUT_REQUESTED: {
      return {
        ...defaultState,
        jwtError: action.jwtError || false
      };
    }
    case ENABLE_USER_CANCELLED: {
      return {
        ...defaultState
      };
    }
    case LOGIN_FACEBOOK_REQUESTED:
    case LOGIN_GOOGLE_REQUESTED:
    case LOGIN_REQUESTED: {
      return {
        ...state,
        status: LOGIN_REQUESTED,
        token: null,
        isLoggedIn: false,
        loading: true,
        user: null,
        error: null
      };
    }
    case LOGIN_FACEBOOK_SUCCESSFULL:
    case LOGIN_GOOGLE_SUCCESSFULL:
    case LOGIN_SUCCESSFULL: {
      const { token, user } = action;
      return {
        ...state,
        status: LOGIN_SUCCESSFULL,
        token: token || state.token,
        loading: false,
        isLoggedIn: true,
        user: user || state.user,
        disabledUser: false
      };
    }
    case LOGIN_FACEBOOK_ERRORED:
    case LOGIN_GOOGLE_ERRORED:
    case LOGIN_ERRORED: {
      Reactotron.debug(action, true);
      return {
        ...state,
        status: LOGIN_ERRORED,
        error: action.error,
        token: null,
        isLoggedIn: false,
        loading: false,
        user: null
      };
    }

    case LOGIN_DISABLED_SUCCESSFULL: {
      return {
        ...state,
        status: LOGIN_DISABLED_SUCCESSFULL,
        token: action.token,
        user: action.user,
        disabledUser: true
      };
    }
    case ENABLE_USER_SUCCESSFULL: {
      return {
        ...state,
        disabledUser: false,
        isLoggedIn: true
      };
    }
    case REGISTER_REQUESTED: {
      return {
        ...state,
        status: REGISTER_REQUESTED
      };
    }
    case REGISTER_ERRORED: {
      return {
        ...state,
        status: REGISTER_ERRORED,
        error: action.error
      };
    }
    case REGISTER_SUCCESSFULL: {
      return {
        ...state,
        status: REGISTER_SUCCESSFULL,
        user: action.user,
        token: action.token,
        isLoggedIn: true,
        lastPINRequested: new Date().getTime()
      };
    }
    case CHECK_FACEBOOK_REQUESTED: {
      return {
        ...state,
        status: CHECK_FACEBOOK_REQUESTED
      };
    }
    case CHECK_FACEBOOK_DUPLICATED: {
      return {
        ...state,
        status: CHECK_FACEBOOK_DUPLICATED
      };
    }
    case CHECK_FACEBOOK_ERRORED: {
      return {
        ...state,
        status: CHECK_FACEBOOK_ERRORED
      };
    }
    case CHANGE_EMAIL_REQUESTED: {
      return {
        ...state,
        status: CHANGE_EMAIL_REQUESTED
      };
    }
    case CHANGE_EMAIL_SUCCESSFULL: {
      return {
        ...state,
        status: CHANGE_EMAIL_SUCCESSFULL,
        lastPINRequested: new Date().getTime()
      };
    }
    case CHANGE_EMAIL_ERRORED: {
      return {
        ...state,
        status: CHANGE_EMAIL_ERRORED
      };
    }
    case PIN_VERIFY_SUCCESSFULL: {
      return {
        ...state,
        user: {
          ...state.user,
          phoneVerified: state.user.emailVerified,
          emailVerified: true
        }
      };
    }
    case CHANGE_PHONE_REQUESTED: {
      return {
        ...state,
        status: CHANGE_PHONE_REQUESTED,
        loading: true
      };
    }
    case CHANGE_PHONE_SUCCESSFULL: {
      return {
        ...state,
        status: CHANGE_PHONE_SUCCESSFULL,
        loading: false,
        user: {
          ...state.user,
          phone: action.phone
        },
        lastPINRequested: new Date().getTime()
      };
    }
    case CHANGE_PHONE_ERRORED: {
      return {
        ...state,
        status: CHANGE_PHONE_ERRORED,
        error: action.error,
        loading: false
      };
    }
    case UPDATE_USER_SUCCESSFULL: {
      return {
        ...state,
        status: UPDATE_USER_SUCCESSFULL,
        loading: false,
        user: {
          ...state.user,
          hasSellingAddress:
            action.user.selling_address && action.user.selling_address.id
              ? true
              : state.user.hasSellingAddress,
          hasShippingOptions:
            action.user.shipping_carrier && action.user.shipping_carrier.length
              ? true
              : state.user.hasShippingOptions
        },
        userChanges: action.userChanges
      };
    }
    case UPDATE_USER_ERRORED: {
      let error = action.error?.data;
      if (
        action.error?.data?.error?.exception &&
        Array.isArray(action.error?.data?.error?.exception) &&
        action.error?.data?.error?.exception.length > 0
      ) {
        error = action.error.data.error.exception[0];
      } else if (action.error?.data?.error?.exception?.data) {
        error = action.error.data.error.exception.data;
      }
      return {
        ...state,
        status: UPDATE_USER_ERRORED,
        loading: false,
        error,
        userChanges: action.userChanges
      };
    }
    case UPDATE_USER_REQUESTED: {
      return {
        ...state,
        loading: true,
        status: UPDATE_USER_REQUESTED,
        userChanges: action.userChanges
      };
    }
    case UPDATE_USER_MEDIA_REQUESTED: {
      return {
        ...state,
        status: UPDATE_USER_MEDIA_REQUESTED
      };
    }
    case UPDATE_USER_MEDIA_SUCCESSFULL: {
      return {
        ...state,
        status: UPDATE_USER_MEDIA_SUCCESSFULL,
        user: {
          ...state.user,
          ...action.user
        }
      };
    }
    case UPDATE_USER_MEDIA_ERRORED: {
      return {
        ...state,
        status: UPDATE_USER_MEDIA_ERRORED,
        error: action.error
      };
    }
    case IDENTIFIER_VERIFICATION_REQUESTED: {
      return {
        ...state,
        status: IDENTIFIER_VERIFICATION_REQUESTED
      };
    }
    case IDENTIFIER_VERIFICATION_SUCCESSFULL: {
      return {
        ...state,
        identifierType: action.identifierType,
        status: IDENTIFIER_VERIFICATION_SUCCESSFULL
      };
    }
    case IDENTIFIER_VERIFICATION_ERRORED: {
      return {
        ...state,
        identifierType: action.identifierType,
        status: IDENTIFIER_VERIFICATION_ERRORED
      };
    }
    case IDENTIFIER_RESEND_REQUESTED: {
      return {
        ...state,
        status: IDENTIFIER_RESEND_REQUESTED
      };
    }
    case IDENTIFIER_RESEND_SUCCESSFULL: {
      return {
        ...state,
        status: IDENTIFIER_RESEND_SUCCESSFULL,
        lastPINRequested: new Date().getTime()
      };
    }
    case IDENTIFIER_RESEND_ERRORED: {
      return {
        ...state,
        status: IDENTIFIER_RESEND_ERRORED
      };
    }
    case VALIDATE_SHOWROOM: {
      return {
        ...state,
        user: {
          ...state.user,
          roles: [
            ...state.user.roles.filter(
              role => role !== 'ROLE_SHOWROOM_PENDING'
            ),
            'ROLE_SHOWROOM'
          ]
        }
      };
    }
    case PIN_OPTIONS_REQUESTED: {
      return {
        ...state,
        status: PIN_OPTIONS_REQUESTED
      };
    }
    case PIN_OPTIONS_RETRIEVED: {
      return {
        ...state,
        status: PIN_OPTIONS_RETRIEVED,
        pinOptions: action.options
      };
    }
    case PIN_OPTIONS_FAILED: {
      return {
        ...state,
        status: PIN_OPTIONS_FAILED,
        error: action.error
      };
    }
    case PIN_LOGIN_REQUESTED: {
      return {
        ...state,
        status: PIN_LOGIN_REQUESTED
      };
    }
    case PIN_LOGIN_SUCCESSFULL: {
      return {
        ...state,
        status: PIN_LOGIN_SUCCESSFULL
      };
    }
    case PIN_LOGIN_ERRORED: {
      return {
        ...state,
        status: PIN_LOGIN_ERRORED,
        error: action.error
      };
    }
    case LOGIN_PIN_REQUESTED: {
      return {
        ...state,
        status: LOGIN_PIN_REQUESTED
      };
    }
    case LOGIN_PIN_SUCCESSFULL: {
      return {
        ...state,
        status: LOGIN_PIN_SUCCESSFULL,
        token: action.token,
        user: action.user,
        recoveringPassword: true
      };
    }
    case LOGIN_PIN_ERRORED: {
      return {
        ...state,
        status: LOGIN_PIN_ERRORED,
        error: action.error
      };
    }
    case CANCEL_REGISTRATION_SUCCESSFULL: {
      return {
        ...state,
        recoveringPassword: false
      };
    }
    case UPDATE_USER_PASSWORD_SUCCESSFULL: {
      if (state.recoveringPassword) {
        return {
          ...state,
          isLoggedIn: true,
          recoveringPassword: false
        };
      }
      return state;
    }
    case PENDING_ID_STORED: {
      return {
        ...state,
        pendingReviewId: action.pendingId
      };
    }
    case PIN_VOICE_CALL_REQUESTED: {
      return {
        ...state,
        status: PIN_VOICE_CALL_REQUESTED
      };
    }
    case PIN_VOICE_CALL_SUCCESSFUL: {
      return {
        ...state,
        status: PIN_VOICE_CALL_SUCCESSFUL,
        lastCallRequested: new Date().getTime()
      };
    }
    case PIN_VOICE_CALL_FAILED: {
      return {
        ...state,
        status: PIN_VOICE_CALL_FAILED
      };
    }
    case PERSONAL_INFO_POPUP_CLOSED: {
      return {
        ...state,
        status: action.type
      };
    }
    case CHECK_MERCADOPAGO_REQUESTED: {
      return {
        ...state,
        status: CHECK_MERCADOPAGO_REQUESTED,
        loading: true,
        error: null
      };
    }
    case CHECK_MERCADOPAGO_SUCCESSFULL: {
      return {
        ...state,
        status: CHECK_MERCADOPAGO_SUCCESSFULL,
        loading: false
      };
    }
    case CHECK_MERCADOPAGO_ERRORED: {
      let error = action.error?.data;
      if (
        action.error?.data?.error?.exception &&
        Array.isArray(action.error?.data?.error?.exception) &&
        action.error?.data?.error?.exception.length > 0
      ) {
        error = action.error.data.error.exception[0];
      } else if (action.error?.data?.error?.exception?.data) {
        error = action.error.data.error.exception.data;
      }
      return {
        ...state,
        status: CHECK_MERCADOPAGO_ERRORED,
        loading: false,
        error
      };
    }
    case DOWNLOAD_APP_BANNER_CLOSED: {
      return {
        ...state,
        status: action.type,
        downloadAppBannerExpireDate: moment().add(3, 'days').format('X')
      };
    }
    case UPDATE_BY_PRIVATE_STATS: {
      return {
        ...state,
        status: action.type,
        roles: action.stats?.roles || state.roles,
        user: {
          ...state.user,
          roles: action.stats?.roles || state.userDetails.roles
        }
      };
    }
    default:
      return state;
  }
}
