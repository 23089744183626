import React from 'react';
import LoginForm from '../login/LoginForm';
import { useExpressModalStyles } from './ExpressModal';
import { Typography } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { googleLogin } from '../../../redux/actions/auth';
import { GoogleLogin } from '@react-oauth/google';

const LoginStep = ({ onChangeStep }) => {
  const classes = useExpressModalStyles();
  const dispatch = useDispatch();

  const onGoogleLogin = credentialsResponse => {
    dispatch(googleLogin(credentialsResponse.token));
  };

  return (
    <div style={{ width: '275px' }} className={classes.dialogContent}>
      <Typography className={classes.title}>Ya tenés cuenta?</Typography>
      <LoginForm />

      <div className={classes.googleLine}>
        <Typography className={classes.googleText}>O ingresá con</Typography>
        <GoogleLogin
          onSuccess={onGoogleLogin}
          onError={() => {
            console.log('Login with google failed');
          }}
          shape="circle"
          type="icon"
        />
      </div>

      <Typography
        style={{
          cursor: 'pointer',
          textDecoration: 'underline',
          color: '#000',
          marginTop: '8px'
        }}
        color="secondary"
        onClick={() => onChangeStep('RegisterStep')}
      >
        No tenés cuenta? Registrate
      </Typography>
    </div>
  );
};

export default LoginStep;
