import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Dialog, Button, Typography } from '@material-ui/core';

const styles = theme => ({
  dialogContent: {
    padding: theme.spacing(3),
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    '& > *': {
      margin: theme.spacing(1)
    }
  }
});

const errors = {
  auth: {
    408: {
      title: 'No pudimos encontrar tus datos',
      message:
        'No pudimos encontrar tus datos en la AFIP, de momento podés continuar con tus ventas, te pediremos más adelante tu CUIT/CUIL'
    }
  },
  login: {
    401: {
      title: 'No autorizada',
      message: 'El nombre de usuario o mail y/o la contraseña son incorrectas!'
    },
    404: {
      title: 'Usuario no encontrado',
      message: 'No se ha encontrado usuario registrado con estos datos!'
      // onClose: () => store.dispatch(push('/signup'))
    },
    403: {
      title: 'ERROR!',
      message:
        'Tu usuario ha sido bloqueado para ingresar.  Comunicate con el equipo de atención al cliente. Gracias!'
    },
    disabled: {
      title: 'Tu usuario esta deshabilito',
      message:
        'Deshabilitaste o deshibilitaron tu usuario, vas a tener que seguir el flujo para rehabilitarlo!'
    }
  },
  default: {
    title: 'Error!',
    message: 'No sabemos que ocurrió! Pero hubo un problema!'
  }
};

const RTVErrorDialog = ({
  open,
  onClose,
  error,
  errorMessage,
  action,
  classes
}) => {
  let errorSpec = errorMessage || errors.default;

  if (error) {
    errorSpec = errors[action]
      ? errors[action][error.status] || errors.default
      : errors.default;
  }

  return (
    <Dialog open={open} onClose={errorSpec.onClose || onClose}>
      <div className={classes.dialogContent}>
        <Typography variant="h5">{errorSpec.title}</Typography>
        <Typography variant="body2">{errorSpec.message}</Typography>
        <Button
          variant="contained"
          onClick={errorSpec.onClose || onClose}
          color="primary"
        >
          OK
        </Button>
      </div>
    </Dialog>
  );
};

export default withStyles(styles)(RTVErrorDialog);
