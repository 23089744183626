import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import RTVDialog from '../../../commons/dialogs/RTVDialog';
import { expressToggle } from '../../../redux/actions/app';
import { makeStyles, Typography } from '@material-ui/core';

import RenovaLogo from '../../../images/Logos/RenovaTuVestidor-full.png';
import RtvButton from '../../../commons/Buttons/RtvButton';
import LoginStep from './LoginStep';

import { ReactComponent as Mail } from '../../../images/Icons/mail.svg';
import { ReactComponent as GoogleLogo } from '../../../images/Logos/Google/google-logo.svg';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useGoogleLogin } from '@react-oauth/google';

import AuthRestClient from '../../../http/client/Authentication';
import clsx from 'clsx';

const FirstStep = ({ onChangeStep }) => {
  const classes = useExpressModalStyles();
  const history = useHistory();
  return (
    <>
      <div
        style={{ paddingBottom: '90px', paddingTop: '16px' }}
        className={classes.dialogContent}
      >
        <Typography className={classes.title}>Nuevo por acá?</Typography>
        <RtvButton
          variant="rtvRed"
          className={classes.button}
          onClick={() => onChangeStep('RegisterStep')}
        >
          Registrate
        </RtvButton>
        <Typography style={{ marginTop: '16px' }} className={classes.title}>
          Tenés una marca?
        </Typography>
        <RtvButton
          variant="rtvRed"
          className={classes.button}
          onClick={() => history.push('/register', { showroom: true })}
        >
          Solicitá tu showroom
        </RtvButton>
      </div>
      <div className={classes.footer}>
        <Typography className={classes.title}>Ya tenés cuenta?</Typography>
        <RtvButton
          variant="secondary"
          className={classes.button}
          onClick={() => onChangeStep('LoginStep')}
        >
          <span style={{ color: '#000' }}>Ingresá</span>
        </RtvButton>
      </div>
    </>
  );
};

const RegisterStep = () => {
  const classes = useExpressModalStyles();
  const history = useHistory();

  const goToRegister = () => {
    history.push('/register');
  };

  const googleSignUp = useGoogleLogin({
    onSuccess: async tokenResponse => {
      const response = await AuthRestClient.fetchGoogleInfo(
        tokenResponse.access_token
      );

      const userInfo = response.data;

      if (userInfo?.email_verified) {
        const userData = {
          first_name: userInfo.given_name,
          last_name: userInfo.family_name,
          email: userInfo.email
        };
        history.push('/register', {
          ...userData
        });
      } else {
        console.log('error fetching info');
      }
    },
    onError: error => {
      console.log('Google login error:', error);
    }
  });

  return (
    <div style={{ paddingBottom: '70px' }} className={classes.dialogContent}>
      <div className={clsx(classes.footer, classes.footerRegister)}>
        <Typography className={classes.title}>Registrate</Typography>
        <div className={classes.registerButtons}>
          <RtvButton variant="secondary" onClick={goToRegister}>
            <Mail />
            <span style={{ color: '#000' }}>con Mail</span>
          </RtvButton>
          <RtvButton onClick={googleSignUp} variant="secondary">
            <GoogleLogo />
            <span style={{ color: '#000' }}>con Google</span>
          </RtvButton>
        </div>
      </div>
    </div>
  );
};

const STEPS = {
  FirstStep: {
    id: 1,
    Component: FirstStep
  },
  RegisterStep: {
    id: 2,
    Component: RegisterStep,
    BackStep: FirstStep
  },
  LoginStep: {
    id: 3,
    Component: LoginStep,
    BackStep: FirstStep
  }
};

const ExpressModal = () => {
  // TODO: check when changing URLs if we should close the modal.
  // for example: when you select "forgot my password" and you're redirected to the
  // recover password flow.

  const dispatch = useDispatch();
  const classes = useExpressModalStyles();
  const expressDialog = useSelector(state => state.app.expressDialog);
  const [currentStep, setCurrentStep] = useState(STEPS.FirstStep);
  const location = useLocation();
  const locationToCheck = useRef(null);

  const Step = currentStep.Component;

  const handleModalClose = useCallback(() => {
    locationToCheck.current = null;
    dispatch(expressToggle(false));
  }, [dispatch]);

  const handleCleanup = () => {
    setCurrentStep(STEPS.FirstStep);
  };

  const handleChangeStep = step => {
    setCurrentStep(STEPS[step]);
  };

  useEffect(() => {
    if (location.pathname === '/register') {
      handleModalClose();
    }

    if (locationToCheck.current === null) {
      locationToCheck.current = location.pathname;
      return;
    }

    if (locationToCheck.current !== location.pathname) {
      handleModalClose();
    }
  }, [location, handleModalClose]);

  return (
    <RTVDialog
      open={expressDialog}
      onClose={handleModalClose}
      dialogProps={{
        onExited: handleCleanup
      }}
      noTitle
      noActionSection
      backIcon={currentStep.id === 2}
      onBackIcon={() => handleChangeStep('FirstStep')}
      content={
        <div className={classes.root}>
          <div className={classes.header}>
            <img
              className={classes.logo}
              src={RenovaLogo}
              alt="Logo de Renova tu vestidor"
            />
          </div>
          <Step onChangeStep={handleChangeStep} />
        </div>
      }
    />
  );
};

export const useExpressModalStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexFlow: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  dialogContent: {
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      height: '300px'
    }
  },
  backArrow: {
    position: 'absolute',
    left: theme.spacing(0.5),
    top: theme.spacing(0.5),
    cursor: 'pointer',
    color: '#707070',
    transition: 'all 1s ease-out',
    '&:hover': {
      color: '#000'
    }
  },
  header: {
    position: 'relative',
    marginTop: '-45px' // a little hack to use the RTVDialog and have the logo header where the design wants it
  },
  logo: {
    maxWidth: '300px'
  },
  footer: {
    position: 'absolute',
    bottom: 0,
    backgroundColor: theme.palette.yellow.background,
    display: 'flex',
    flexFlow: 'column',
    padding: theme.spacing(2),
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    height: '90px'
  },
  title: {
    fontWeight: 600,
    fontSize: '1.1rem',
    color: '#000'
  },
  button: {
    width: '195px'
  },
  registerButtons: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& > button': {
      width: '140px',
      margin: theme.spacing(1),
      '& > span': {
        '& > svg': {
          height: '20px',
          marginRight: theme.spacing(1)
        }
      }
    }
  },
  googleLine: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  googleText: {
    marginRight: theme.spacing(1),
    fontWeight: 600
  },
  footerRegister: {
    height: '100px',
    [theme.breakpoints.down('sm')]: {
      height: '200px'
    }
  }
}));

export default ExpressModal;
