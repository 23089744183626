// App State Actions
export const STATE_REGISTERING = 'STATE_REGISTERING';
export const STATE_WELCOME = 'STATE_WELCOME';
export const STATE_APP = 'STATE_APP';
export const SW_UPDATE = 'SW_UPDATE';
export const SW_UPDATE_FINISHED = 'SW_UPDATE_FINISHED';
export const SW_INIT = 'SW_INIT';
export const HEART_BEAT_REQUESTED = 'HEART_BEAT_REQUESTED';
export const HEART_BEAT_RETRIEVED = 'HEART_BEAT_RETRIEVED';
export const HEART_BEAT_FAILED = 'HEART_BEAT_FAILED';
export const SET_FROM_URL = 'SET_FROM_URL';

// Recover password
export const PIN_OPTIONS_REQUESTED = 'PIN_OPTIONS_REQUESTED';
export const PIN_OPTIONS_RETRIEVED = 'PIN_OPTIONS_RETRIEVED';
export const PIN_OPTIONS_FAILED = 'PIN_OPTIONS_FAILED';

// Request the server to send the PIN to the channel specified
export const PIN_LOGIN_REQUESTED = 'PIN_LOGIN_REQUESTED';
export const PIN_LOGIN_SUCCESSFULL = 'PIN_LOGIN_SUCCESSFULL';
export const PIN_LOGIN_ERRORED = 'PIN_LOGIN_ERRORED';

// Let you login using your pin (used to force you to reset your password)
export const LOGIN_PIN_REQUESTED = 'LOGIN_PIN_REQUESTED';
export const LOGIN_PIN_SUCCESSFULL = 'LOGIN_PIN_SUCCESSFULL';
export const LOGIN_PIN_ERRORED = 'LOGIN_PIN_ERRORED';

// Push notifications
export const REGISTER_PUSH_TOKEN_REQUESTED = 'REGISTER_PUSH_TOKEN_REQUESTED';
export const REGISTER_PUSH_TOKEN_SUCCESSFULL =
  'REGISTER_PUSH_TOKEN_SUCCESSFULL';
export const REGISTER_PUSH_TOKEN_ERRORED = 'REGISTER_PUSH_TOKEN_ERRORED';

export const UNREGISTER_PUSH_TOKEN_REQUESTED =
  'UNREGISTER_PUSH_TOKEN_REQUESTED';
export const UNREGISTER_PUSH_TOKEN_SUCCESSFULL =
  'UNREGISTER_PUSH_TOKEN_SUCCESSFULL';
export const UNREGISTER_PUSH_TOKEN_ERRORED = 'UNREGISTER_PUSH_TOKEN_ERRORED';

// Cancel registration
export const CANCEL_REGISTRATION_REQUESTED = 'CANCEL_REGISTRATION_REQUESTED';
export const CANCEL_REGISTRATION_SUCCESSFULL =
  'CANCEL_REGISTRATION_SUCCESSFULL';
export const CANCEL_REGISTRATION_ERRORED = 'CANCEL_REGISTRATION_ERRORED';

// UTM Management
export const UTM_STORE = 'UTM_STORE'; // For when we store the UTM in localStats
export const UTM_REMOVE = 'UTM_REMOVE'; // For when we remove the UTM from localStats
export const POST_UTM_REQUESTED = 'POST_UTM_REQUESTED';
export const POST_UTM_SUCCESSFULL = 'POST_UTM_SUCCESSFULL';
export const POST_UTM_ERRORED = 'POST_UTM_ERRORED';

// Validate stats
export const VALIDATE_SHOWROOM = 'VALIDATE_SHOWROOM';

// Auth
export const LOGOUT_REQUESTED = 'LOGOUT_REQUESTED';
export const LOGIN_REQUESTED = 'LOGIN_REQUESTED';
export const LOGIN_SUCCESSFULL = 'LOGIN_SUCCESSFULL';
export const LOGIN_DISABLED_SUCCESSFULL = 'LOGIN_DISABLED_SUCCESSFULL';
export const LOGIN_ERRORED = 'LOGIN_ERRORED';
export const LOGIN_FACEBOOK_REQUESTED = 'LOGIN_FACEBOOK_REQUESTED';
export const LOGIN_FACEBOOK_SUCCESSFULL = 'LOGIN_FACEBOOK_SUCCESSFULL';
export const LOGIN_FACEBOOK_ERRORED = 'LOGIN_FACEBOOK_ERRORED';
export const LOGIN_GOOGLE_REQUESTED = 'LOGIN_GOOGLE_REQUESTED';
export const LOGIN_GOOGLE_SUCCESSFULL = 'LOGIN_GOOGLE_SUCCESSFULL';
export const LOGIN_GOOGLE_ERRORED = 'LOGIN_GOOGLE_ERRORED';

// Registration process
export const CHECK_FACEBOOK_REQUESTED = 'CHECK_FACEBOOK_REQUESTED';
export const CHECK_FACEBOOK_DUPLICATED = 'CHECK_FACEBOOK_DUPLICATED';
export const CHECK_FACEBOOK_ERRORED = 'CHECK_FACEBOOK_ERRORED';

export const REGISTER_REQUESTED = 'REGISTER_REQUESTED';
export const REGISTER_ERRORED = 'REGISTER_ERRORED';
export const REGISTER_SUCCESSFULL = 'REGISTER_SUCCESSFULL';

// Identifier verification
export const IDENTIFIER_VERIFICATION_REQUESTED =
  'IDENTIFIER_VERIFICATION_REQUESTED';
export const IDENTIFIER_VERIFICATION_SUCCESSFULL =
  'IDENTIFIER_VERIFICATION_SUCCESSFULL';
export const IDENTIFIER_VERIFICATION_ERRORED =
  'IDENTIFIER_VERIFICATION_ERRORED';

export const IDENTIFIER_RESEND_REQUESTED = 'IDENTIFIER_RESEND_REQUESTED';
export const IDENTIFIER_RESEND_SUCCESSFULL = 'IDENTIFIER_RESEND_SUCCESSFULL';
export const IDENTIFIER_RESEND_ERRORED = 'IDENTIFIER_RESEND_ERRORED';

// Email change process
export const CHANGE_EMAIL_REQUESTED = 'CHANGE_EMAIL_REQUESTED';
export const CHANGE_EMAIL_SUCCESSFULL = 'CHANGE_EMAIL_SUCCESSFULL';
export const CHANGE_EMAIL_ERRORED = 'CHANGE_EMAIL_ERRORED';

// Phone change process
export const CHANGE_PHONE_REQUESTED = 'CHANGE_PHONE_REQUESTED';
export const CHANGE_PHONE_SUCCESSFULL = 'CHANGE_PHONE_SUCCESSFULL';
export const CHANGE_PHONE_ERRORED = 'CHANGE_PHONE_ERRORED';

// Pin verification
export const PIN_VERIFY_REQUESTED = 'PIN_VERIFY_REQUESTED';
export const PIN_VERIFY_SUCCESSFULL = 'PIN_VERIFY_SUCCESSFULL';
export const PIN_VERIFY_FAILED = 'PIN_VERIFY_FAILED';

// Update user information
export const UPDATE_USER_REQUESTED = 'UPDATE_USER_REQUESTED';
export const UPDATE_USER_SUCCESSFULL = 'UPDATE_USER_SUCCESSFULL';
export const UPDATE_USER_ERRORED = 'UPDATE_USER_ERRORED';

export const UPDATE_USER_PASSWORD_REQUESTED = 'UPDATE_USER_PASSWORD_REQUESTED';
export const UPDATE_USER_PASSWORD_SUCCESSFULL =
  'UPDATE_USER_PASSWORD_SUCCESSFULL';
export const UPDATE_USER_PASSWORD_ERRORED = 'UPDATE_USER_PASSWORD_ERRORED';

// User stats
export const USER_PRIVATE_STATS_REQUESTED = 'USER_PRIVATE_STATS_REQUESTED';
export const USER_PRIVATE_STATS_RETRIEVED = 'USER_PRIVATE_STATS_RETRIEVED';
export const USER_PRIVATE_STATS_FAILED = 'USER_PRIVATE_STATS_FAILED';

export const USER_PUBLIC_STATS_REQUESTED = 'USER_PUBLIC_STATS_REQUESTED';
export const USER_PUBLIC_STATS_RETRIEVED = 'USER_PUBLIC_STATS_RETRIEVED';
export const USER_PUBLIC_STATS_FAILED = 'USER_PUBLIC_STATS_FAILED';

// User
export const USER_SELF_REQUESTED = 'USER_SELF_REQUESTED';
export const USER_SELF_RETRIEVED = 'USER_SELF_RETRIEVED';
export const USER_SELF_FAILED = 'USER_SELF_FAILED';

export const USER_REQUESTED = 'USER_REQUESTED';
export const USER_RETRIEVED = 'USER_RETRIEVED';
export const USER_FAILED = 'USER_FAILED';

export const USER_RECEIVED_REVIEWS_REQUESTED =
  'USER_RECEIVED_REVIEWS_REQUESTED';
export const USER_RECEIVED_REVIEWS_RETRIEVED =
  'USER_RECEIVED_REVIEWS_RETRIEVED';
export const USER_RECEIVED_REVIEWS_FAILED = 'USER_RECEIVED_REVIEWS_FAILED';

export const DRESSERS_REQUESTED = 'DRESSERS_REQUESTED';
export const DRESSERS_RETRIEVED = 'DRESSERS_RETRIEVED';
export const DRESSERS_FAILED = 'DRESSERS_FAILED';

export const ACTIVATE_HOLIDAY_MODE_REQUESTED =
  'ACTIVATE_HOLIDAY_MODE_REQUESTED';
export const ACTIVATE_HOLIDAY_MODE_SUCCESSFULL =
  'ACTIVATE_HOLIDAY_MODE_SUCCESSFULL';
export const ACTIVATE_HOLIDAY_MODE_ERRORED = 'ACTIVATE_HOLIDAY_MODE_ERRORED';

export const DEACTIVATE_HOLIDAY_MODE_REQUESTED =
  'DEACTIVATE_HOLIDAY_MODE_REQUESTED';
export const DEACTIVATE_HOLIDAY_MODE_SUCCESSFULL =
  'DEACTIVATE_HOLIDAY_MODE_SUCCESSFULL';
export const DEACTIVATE_HOLIDAY_MODE_ERRORED =
  'DEACTIVATE_HOLIDAY_MODE_ERRORED';

export const ENABLE_USER_REQUESTED = 'ENABLE_USER_REQUESTED';
export const ENABLE_USER_SUCCESSFULL = 'ENABLE_USER_SUCCESSFULL';
export const ENABLE_USER_ERRORED = 'ENABLE_USER_ERRORED';
export const ENABLE_USER_CANCELLED = 'ENABLE_USER_CANCELLED';

export const DISABLE_USER_REQUESTED = 'DISABLE_USER_REQUESTED';
export const DISABLE_USER_ERRORED = 'DISABLE_USER_ERRORED';

export const HOLIDAY_CHECK_PERFORMED = 'HOLIDAY_CHECK_PERFORMED';

// User promotions
export const CREATE_PROMOTION_REQUESTED = 'CREATE_PROMOTION_REQUESTED';
export const CREATE_PROMOTION_SUCCESSFULL = 'CREATE_PROMOTION_SUCCESSFULL';
export const CREATE_PROMOTION_ERRORED = 'CREATE_PROMOTION_ERRORED';

export const DELETE_PROMOTION_REQUESTED = 'DELETE_PROMOTION_REQUESTED';
export const DELETE_PROMOTION_SUCCESSFULL = 'DELETE_PROMOTION_SUCCESSFULL';
export const DELETE_PROMOTION_ERRORED = 'DELETE_PROMOTION_ERRORED';

// Update Media (Profile picture) for the user
export const UPDATE_USER_MEDIA_REQUESTED = 'UPDATE_USER_MEDIA_REQUESTED';
export const UPDATE_USER_MEDIA_SUCCESSFULL = 'UPDATE_USER_MEDIA_SUCCESSFULL';
export const UPDATE_USER_MEDIA_ERRORED = 'UPDATE_USER_MEDIA_ERRORED';

// Sizes (Top, Bottom, Shoes)
export const SIZES_REQUESTED = 'SIZES_REQUESTED';
export const SIZES_RETRIEVED = 'SIZES_RETRIEVED';
export const SIZES_FAILED = 'SIZES_FAILED';

export const SIZES_ALL_REQUESTED = 'SIZES_ALL_REQUESTED';
export const SIZES_ALL_RETRIEVED = 'SIZES_ALL_RETRIEVED';
export const SIZES_ALL_FAILED = 'SIZES_ALL_FAILED';

// Brands
export const BRANDS_REQUESTED = 'BRANDS_REQUESTED';
export const BRANDS_RETRIEVED = 'BRANDS_RETRIEVED';
export const BRANDS_FAILED = 'BRANDS_FAILED';

export const FEATURED_BRANDS_REQUESTED = 'FEATURED_BRANDS_REQUESTED';
export const FEATURED_BRANDS_RETRIEVED = 'FEATURED_BRANDS_RETRIEVED';
export const FEATURED_BRANDS_FAILED = 'FEATURED_BRANDS_FAILED';

// Categories
export const CATEGORIES_REQUESTED = 'CATEGORIES_REQUESTED';
export const CATEGORIES_RETRIEVED = 'CATEGORIES_RETRIEVED';
export const CATEGORIES_FAILED = 'CATEGORIES_FAILED';

export const SUBSUBCAT_REQUESTED = 'SUBSUBCAT_REQUESTED';
export const SUBSUBCAT_RETRIEVED = 'SUBSUBCAT_RETRIEVED';
export const SUBSUBCAT_FAILED = 'SUBSUBCAT_FAILED';

// User Recommends
export const USERS_RECOMMENDS_REQUESTED = 'USERS_RECOMMENDS_REQUESTED';
export const USERS_RECOMMENDS_RETRIEVED = 'USERS_RECOMMENDS_RETRIEVED';
export const USERS_RECOMMENDS_FAILED = 'USERS_RECOMMENDS_FAILED';

// Follow actions
export const FOLLOW_LIST_REQUESTED = 'FOLLOW_LIST_REQUESTED';
export const FOLLOW_LIST_RETRIEVED = 'FOLLOW_LIST_RETRIEVED';
export const FOLLOW_LIST_FAILED = 'FOLLOW_LIST_FAILED';

export const FOLLOW_REQUESTED = 'FOLLOW_REQUESTED';
export const FOLLOW_SUCCEED = 'FOLLOW_SUCCEED';
export const FOLLOW_FAILED = 'FOLLOW_FAILED';

export const UNFOLLOW_REQUESTED = 'UNFOLLOW_REQUESTED';
export const UNFOLLOW_SUCCEED = 'UNFOLLOW_SUCCEED';
export const UNFOLLOW_FAILED = 'UNFOLLOW_FAILED';

export const FOLLOWERS_REQUESTED = 'FOLLOWERS_REQUESTED';
export const FOLLOWERS_RETRIEVED = 'FOLLOWERS_RETRIEVED';
export const FOLLOWERS_FAILED = 'FOLLOWERS_FAILED';

export const FOLLOWINGS_REQUESTED = 'FOLLOWINGS_REQUESTED';
export const FOLLOWINGS_RETRIEVED = 'FOLLOWINGS_RETRIEVED';
export const FOLLOWINGS_FAILED = 'FOLLOWINGS_FAILED';

export const ACTIVE_FOLLOWINGS_REQUESTED = 'ACTIVE_FOLLOWINGS_REQUESTED';
export const ACTIVE_FOLLOWINGS_SUCCESSFULL = 'ACTIVE_FOLLOWINGS_SUCCESSFULL';
export const ACTIVE_FOLLOWINGS_ERRORED = 'ACTIVE_FOLLOWINGS_ERRORED';

// Products
export const PRODUCTS_QUERY_REQUESTED = 'PRODUCTS_QUERY_REQUESTED';
export const PRODUCTS_QUERY_RETRIEVED = 'PRODUCTS_QUERY_RETRIEVED';
export const PRODUCTS_QUERY_FAILED = 'PRODUCTS_QUERY_FAILED';

export const PRODUCT_DETAIL_REQUESTED = 'PRODUCT_DETAIL_REQUESTED';
export const PRODUCT_DETAIL_RETRIEVED = 'PRODUCT_DETAIL_RETRIEVED';
export const PRODUCT_DETAIL_FAILED = 'PRODUCT_DETAIL_FAILED';

export const USER_PRODUCTS_REQUESTED = 'USER_PRODUCTS_REQUESTED';
export const USER_PRODUCT_RETRIEVED = 'USER_PRODUCT_RETRIEVED';
export const USER_PRODUCT_FAILED = 'USER_PRODUCT_FAILED';

export const PRODUCTS_LATEST_REQUESTED = 'PRODUCTS_LATEST_REQUESTED';
export const PRODUCTS_LATEST_SUCCESSFULL = 'PRODUCTS_LATEST_SUCCESSFULL';
export const PRODUCTS_LATEST_ERRORED = 'PRODUCTS_LATEST_ERRORED';

export const PRODUCTS_DISCOUNT_REQUESTED = 'PRODUCTS_DISCOUNT_REQUESTED';
export const PRODUCTS_DISCOUNT_SUCCESSFULL = 'PRODUCTS_DISCOUNT_SUCCESSFULL';
export const PRODUCTS_DISCOUNT_ERRORED = 'PRODUCTS_DISCOUNT_ERRORED';

export const PRODUCTS_POPULAR_REQUESTED = 'PRODUCTS_POPULAR_REQUESTED';
export const PRODUCTS_POPULAR_SUCCESSFULL = 'PRODUCTS_POPULAR_SUCCESSFULL';
export const PRODUCTS_POPULAR_ERRORED = 'PRODUCTS_POPULAR_ERRORED';

export const PRODUCTS_ROLE_REQUESTED = 'PRODUCTS_ROLE_REQUESTED';
export const PRODUCTS_ROLE_RETRIEVED = 'PRODUCTS_ROLE_RETRIEVED';
export const PRODUCTS_ROLE_FAILED = 'PRODUCTS_ROLE_FAILED';

export const PRODUCTS_FAVOURITE_BRANDS_REQUESTED =
  'PRODUCTS_FAVOURITE_BRANDS_REQUESTED';
export const PRODUCTS_FAVOURITE_BRANDS_RETRIEVED =
  'PRODUCTS_FAVOURITE_BRANDS_RETRIEVED';
export const PRODUCTS_FAVOURITE_BRANDS_FAILED =
  'PRODUCTS_FAVOURITE_BRANDS_FAILED';

export const PRODUCTS_SIZES_REQUESTED = 'PRODUCTS_SIZES_REQUESTED';
export const PRODUCTS_SIZES_RETRIEVED = 'PRODUCTS_SIZES_RETRIEVED';
export const PRODUCTS_SIZES_FAILED = 'PRODUCTS_SIZES_FAILED';

export const PRODUCTS_CATEGORY_REQUESTED = 'PRODUCTS_CATEGORY_REQUESTED';
export const PRODUCTS_CATEGORY_RETRIEVED = 'PRODUCTS_CATEGORY_RETRIEVED';
export const PRODUCTS_CATEGORY_FAILED = 'PRODUCTS_CATEGORY_FAILED';

export const PRODUCTS_FAVOURITES_REQUESTED = 'PRODUCTS_FAVOURITES_REQUESTED';
export const PRODUCTS_FAVOURITES_RETRIEVED = 'PRODUCTS_FAVOURITES_RETRIEVED';
export const PRODUCTS_FAVOURITES_FAILED = 'PRODUCTS_FAVOURITES_FAILED';

export const PRODUCTS_SUGGESTED_DRESSERS_REQUESTED =
  'PRODUCTS_SUGGESTED_DRESSERS_REQUESTED';
export const PRODUCTS_SUGGESTED_DRESSERS_RETRIEVED =
  'PRODUCTS_SUGGESTED_DRESSERS_RETRIEVED';
export const PRODUCTS_SUGGESTED_DRESSERS_FAILED =
  'PRODUCTS_SUGGESTED_DRESSERS_FAILED';

export const PRODUCTS_FOLLOWINGS_REQUESTED = 'PRODUCTS_FOLLOWINGS_REQUESTED';
export const PRODUCTS_FOLLOWINGS_RETRIEVED = 'PRODUCTS_FOLLOWINGS_RETRIEVED';
export const PRODUCTS_FOLLOWINGS_FAILED = 'PRODUCTS_FOLLOWINGS_FAILED';

export const PRODUCTS_BRAND_REQUESTED = 'PRODUCTS_BRAND_REQUESTED';
export const PRODUCTS_BRAND_RETRIEVED = 'PRODUCTS_BRAND_RETRIEVED';
export const PRODUCTS_BRAND_FAILED = 'PRODUCTS_BRAND_FAILED';

export const PUBLISHING_PRODUCT_REQUESTED = 'PUBLISHING_PRODUCT_REQUESTED';
export const PUBLISHING_PRODUCT_SUCCESSFULL = 'PUBLISHING_PRODUCT_SUCCESSFULL';
export const PUBLISHING_PRODUCT_FAILED = 'PUBLISHING_PRODUCT_FAILED';

export const UPDATE_PRODUCT_REQUESTED = 'UPDATE_PRODUCT_REQUESTED';
export const UPDATE_PRODUCT_SUCCESSFULL = 'UPDATE_PRODUCT_SUCCESSFULL';
export const UPDATE_PRODUCT_ERRORED = 'UPDATE_PRODUCT_ERRORED';

/*
TODO(Toti): Probably will be making the whole update process as one thing
so for now I'll comment out this actions although is very probable that
we will need them in the future.
*/
export const UPDATE_PRODUCT_IMAGES_REQUESTED =
  'UPDATE_PRODUCT_IMAGES_REQUESTED';
export const UPDATE_PRODUCT_IMAGES_SUCCESSFULL =
  'UPDATE_PRODUCT_IMAGES_SUCCESSFULL';
export const UPDATE_PRODUCT_IMAGES_ERRORED = 'UPDATE_PRODUCT_IMAGES_ERRORED';

export const LIKE_PRODUCT_REQUESTED = 'LIKE_PRODUCT_REQUESTED';
export const LIKE_PRODUCT_SUCCESSFULL = 'LIKE_PRODUCT_SUCCESSFULL';
export const LIKE_PRODUCT_FAILED = 'LIKE_PRODUCT_FAILED';

export const UNLIKE_PRODUCT_REQUESTED = 'UNLIKE_PRODUCT_REQUESTED';
export const UNLIKE_PRODUCT_SUCCESSFULL = 'UNLIKE_PRODUCT_SUCCESSFULL';
export const UNLIKE_PRODUCT_FAILED = 'UNLIKE_PRODUCT_FAILED';

export const FAVOURITE_LIST_REQUESTED = 'FAVOURITE_LIST_REQUESTED';
export const FAVOURITE_LIST_RETRIEVED = 'FAVOURITE_LIST_RETRIEVED';
export const FAVOURITE_LIST_FAILED = 'FAVOURITE_LIST_FAILED';

export const REPUBLISH_REQUESTED = 'REPUBLISH_REQUESTED';
export const REPUBLISH_SUCCESSFULL = 'REPUBLISH_SUCCESSFULL';
export const REPUBLISH_FAILED = 'REPUBLISH_FAILED';

export const REPUBLISH_OWNED_REQUESTED = 'REPUBLISH_OWNED_REQUESTED';
export const REPUBLISH_OWNED_SUCCESSFUL = 'REPUBLISH_OWNED_SUCCESSFUL';
export const REPUBLISH_OWNED_FAILED = 'REPUBLISH_OWNED_FAILED';

export const SEND_OFFER_REQUESTED = 'SEND_OFFER_REQUESTED';
export const SEND_OFFER_SUCCESSFULL = 'SEND_OFFER_SUCCESSFULL';
export const SEND_OFFER_ERRORED = 'SEND_OFFER_ERRORED';

export const MY_PRODUCTS_REQUESTED = 'MY_PRODUCTS_REQUESTED';
export const MY_PRODUCTS_RETRIEVED = 'MY_PRODUCTS_RETRIEVED';
export const MY_PRODUCTS_FAILED = 'MY_PRODUCTS_FAILED';

export const OFFER_PRODUCTS_REQUESTED = 'OFFER_PRODUCTS_REQUESTED';
export const OFFER_PRODUCTS_RETRIEVED = 'OFFER_PRODUCTS_RETRIEVED';
export const OFFER_PRODUCTS_FAILED = 'OFFER_PRODUCTS_FAILED';

export const PAUSE_PRODUCT_REQUESTED = 'PAUSE_PRODUCT_REQUESTED';
export const PAUSE_PRODUCT_SUCCESSFULL = 'PAUSE_PRODUCT_SUCCESSFULL';
export const PAUSE_PRODUCT_ERRORED = 'PAUSE_PRODUCT_ERRORED';

export const RESUME_PRODUCT_REQUESTED = 'RESUME_PRODUCT_REQUESTED';
export const RESUME_PRODUCT_SUCCESSFULL = 'RESUME_PRODUCT_SUCCESSFULL';
export const RESUME_PRODUCT_ERRORED = 'RESUME_PRODUCT_ERRORED';

export const CHANGE_PRODUCT_PRICE_REQUESTED = 'CHANGE_PRODUCT_PRICE_REQUESTED';
export const CHANGE_PRODUCT_PRICE_SUCCESSFULL =
  'CHANGE_PRODUCT_PRICE_SUCCESSFULL';
export const CHANGE_PRODUCT_PRICE_ERRORED = 'CHANGE_PRODUCT_PRICE_ERRORED';

export const CHANGE_PRODUCT_STOCK_REQUESTED = 'CHANGE_PRODUCT_STOCK_REQUESTED';
export const CHANGE_PRODUCT_STOCK_SUCCESSFULL =
  'CHANGE_PRODUCT_STOCK_SUCCESSFULL';
export const CHANGE_PRODUCT_STOCK_ERRORED = 'CHANGE_PRODUCT_STOCK_ERRORED';

export const DELETE_PRODUCT_REQUESTED = 'DELETE_PRODUCT_REQUESTED';
export const DELETE_PRODUCT_SUCCESSFULL = 'DELETE_PRODUCT_SUCCESSFULL';
export const DELETE_PRODUCT_ERRORED = 'DELETE_PRODUCT_ERRORED';

export const ACCEPT_OFFER_REQUESTED = 'ACCEPT_OFFER_REQUESTED';
export const ACCEPT_OFFER_SUCCESSFULL = 'ACCEPT_OFFER_SUCCESSFULL';
export const ACCEPT_OFFER_ERRORED = 'ACCEPT_OFFER_ERRORED';

export const COUNTER_OFFER_REQUESTED = 'COUNTER_OFFER_REQUESTED';
export const COUNTER_OFFER_SUCCESSFULL = 'COUNTER_OFFER_SUCCESSFULL';
export const COUNTER_OFFER_ERRORED = 'COUNTER_OFFER_ERRORED';

export const REJECT_OFFER_REQUESTED = 'REJECT_OFFER_REQUESTED';
export const REJECT_OFFER_SUCCESSFULL = 'REJECT_OFFER_SUCCESSFULL';
export const REJECT_OFFER_ERRORED = 'REJECT_OFFER_ERRORED';

// When the user request to be notified for a reservation drop on a product.
export const DROP_NOTIFICATION_REQUESTED = 'DROP_NOTIFICATION_REQUESTED';
export const DROP_NOTIFICATION_SUCCESSFULL = 'DROP_NOTIFICATION_SUCCESSFULL';
export const DROP_NOTIFICATION_ERRORED = 'DROP_NOTIFICATION_ERRORED';

// Comments
export const PRODUCT_COMMENTS_REQUESTED = 'PRODUCT_COMMENTS_REQUESTED';
export const PRODUCT_COMMENTS_RETRIEVED = 'PRODUCT_COMMENTS_RETRIEVED';
export const PRODUCT_COMMENTS_FAILED = 'PRODUCT_COMMENTS_FAILED';

export const PARENT_COMMENT_POST_REQUESTED = 'PARENT_COMMENT_POST_REQUESTED';
export const PARENT_COMMENT_POST_SUCCESSFULL =
  'PARENT_COMMENT_POST_SUCCESSFULL';
export const PARENT_COMMENT_POST_ERRORED = 'PARENT_COMMENT_POST_ERRORED';

export const CHILD_COMMENT_POST_REQUESTED = 'CHILD_COMMENT_POST_REQUESTED';
export const CHILD_COMMENT_POST_SUCCESSFULL = 'CHILD_COMMENT_POST_SUCCESSFULL';
export const CHILD_COMMENT_POST_ERRORED = 'CHILD_COMMENT_POST_ERRORED';

export const SEEN_SELLER_COMMENTS_REQUESTED = 'SEEN_SELLER_COMMENTS_REQUESTED';
export const SEEN_SELLER_COMMENTS_RETRIEVED = 'SEEN_SELLER_COMMENTS_RETRIEVED';
export const SEEN_SELLER_COMMENTS_FAILED = 'SEEN_SELLER_COMMENTS_FAILED';

export const UNSEEN_SELLER_COMMENTS_REQUESTED =
  'UNSEEN_SELLER_COMMENTS_REQUESTED';
export const UNSEEN_SELLER_COMMENTS_RETRIEVED =
  'UNSEEN_SELLER_COMMENTS_RETRIEVED';
export const UNSEEN_SELLER_COMMENTS_FAILED = 'UNSEEN_SELLER_COMMENTS_FAILED';

export const SEEN_BUYER_COMMENTS_REQUESTED = 'SEEN_BUYER_COMMENTS_REQUESTED';
export const SEEN_BUYER_COMMENTS_RETRIEVED = 'SEEN_BUYER_COMMENTS_RETRIEVED';
export const SEEN_BUYER_COMMENTS_FAILED = 'SEEN_BUYER_COMMENTS_FAILED';

export const UNSEEN_BUYER_COMMENTS_REQUESTED =
  'UNSEEN_BUYER_COMMENTS_REQUESTED';
export const UNSEEN_BUYER_COMMENTS_RETRIEVED =
  'UNSEEN_BUYER_COMMENTS_RETRIEVED';
export const UNSEEN_BUYER_COMMENTS_FAILED = 'UNSEEN_BUYER_COMMENTS_FAILED';

export const COMMENT_REQUESTED = 'COMMENT_REQUESTED';
export const COMMENT_RETRIEVED = 'COMMENT_RETRIEVED';
export const COMMENT_FAILED = 'COMMENT_FAILED';

export const MARK_COMMENT_SEEN_REQUESTED = 'MARK_COMMENT_SEEN_REQUESTED';
export const MARK_COMMENT_SEEN_SUCCESSFULL = 'MARK_COMMENT_SEEN_SUCCESSFULL';
export const MARK_COMMENT_SEEN_FAILED = 'MARK_COMMENT_SEEN_FAILED';

// Shipping
export const STATES_REQUESTED = 'STATES_REQUESTED';
export const STATES_RETRIEVED = 'STATES_RETRIEVED';
export const STATES_FAILED = 'STATES_FAILED';

export const REGIONS_REQUESTED = 'REGIONS_REQUESTED';
export const REGIONS_RETRIEVED = 'REGIONS_RETRIEVED';
export const REGIONS_FAILED = 'REGIONS_FAILED';

export const CARRIERS_REQUESTED = 'CARRIERS_REQUESTED';
export const CARRIERS_RETRIEVED = 'CARRIERS_RETRIEVED';
export const CARRIERS_FAILED = 'CARRIERS_FAILED';

export const STORES_REQUESTED = 'STORES_REQUESTED';
export const STORES_RETRIEVED = 'STORES_RETRIEVED';
export const STORES_FAILED = 'STORES_FAILED';

export const SHIPPING_CARRIERS_FAILED = 'SHIPPING_CARRIERS_FAILED';
export const SHIPPING_CARRIERS_REQUESTED = 'SHIPPING_CARRIERS_REQUESTED';
export const SHIPPING_CARRIERS_RETRIEVED = 'SHIPPING_CARRIERS_RETRIEVED';

export const SHIPPING_ESTIMATE_REQUESTED = 'SHIPPING_ESTIMATE_REQUESTED';
export const SHIPPING_ESTIMATE_RETRIEVED = 'SHIPPING_ESTIMATE_RETRIEVED';
export const SHIPPING_ESTIMATE_FAILED = 'SHIPPING_ESTIMATE_FAILED';

export const SHIPPING_MULTI_ESTIMATE_REQUESTED =
  'SHIPPING_MULTI_ESTIMATE_REQUESTED';
export const SHIPPING_MULTI_ESTIMATE_RETRIEVED =
  'SHIPPING_MULTI_ESTIMATE_RETRIEVED';
export const SHIPPING_MULTI_ESTIMATE_FAILED = 'SHIPPING_MULTI_ESTIMATE_FAILED';

export const SHIPPING_ADDRESSES_REQUESTED = 'SHIPPING_ADDRESSES_REQUESTED';
export const SHIPPING_ADDRESSES_RETRIEVED = 'SHIPPING_ADDRESSES_RETRIEVED';
export const SHIPPING_ADDRESSES_FAILED = 'SHIPPING_ADDRESSES_FAILED';

export const SHIPPING_ADDRESS_CREATE_REQUESTED =
  'SHIPPING_ADDRESS_CREATE_REQUESTED';
export const SHIPPING_ADDRESS_CREATE_SUCCESSFULL =
  'SHIPPING_ADDRESS_CREATE_SUCCESSFULL';
export const SHIPPING_ADDRESS_CREATE_FAILED = 'SHIPPING_ADDRESS_CREATE_FAILED';

export const SHIPPING_ADDRESS_DELETE_REQUESTED =
  'SHIPPING_ADDRESS_DELETE_REQUESTED';
export const SHIPPING_ADDRESS_DELETE_SUCCESSFULL =
  'SHIPPING_ADDRESS_DELETE_SUCCESSFULL';
export const SHIPPING_ADDRESS_DELETE_FAILED = 'SHIPPING_ADDRESS_DELETE_FAILED';

export const SHIPPING_STORE_CREATE_REQUESTED =
  'SHIPPING_STORE_CREATE_REQUESTED';
export const SHIPPING_STORE_CREATE_SUCCESSFULL =
  'SHIPPING_STORE_CREATE_SUCCESSFULL';
export const SHIPPING_STORE_CREATE_FAILED = 'SHIPPING_STORE_CREATE_FAILED';

export const PICKUP_STORES_REQUESTED = 'PICKUP_STORES_REQUESTED';
export const PICKUP_STORES_RETRIEVED = 'PICKUP_STORES_RETRIEVED';
export const PICKUP_STORES_FAILED = 'PICKUP_STORES_FAILED';

// Orders
export const ORDER_REQUESTED = 'ORDER_REQUESTED';
export const ORDER_RETRIEVED = 'ORDER_RETRIEVED';
export const ORDER_FAILED = 'ORDER_FAILED';

export const CREATE_ORDER_REQUESTED = 'CREATE_ORDER_REQUESTED';
export const CREATE_ORDER_SUCCESSFULL = 'CREATE_ORDER_SUCCESSFULL';
export const CREATE_ORDER_ERRORED = 'CREATE_ORDER_ERRORED';

export const DELETE_ORDER_REQUESTED = 'DELETE_ORDER_REQUESTED';
export const DELETE_ORDER_SUCCESSFULL = 'DELETE_ORDER_SUCCESSFULL';
export const DELETE_ORDER_ERRORED = 'DELETE_ORDER_ERRORED';

export const DELETE_ORDER_GROUP_REQUESTED = 'DELETE_ORDER_GROUP_REQUESTED';
export const DELETE_ORDER_GROUP_SUCCESSFULL = 'DELETE_ORDER_GROUP_SUCCESSFULL';
export const DELETE_ORDER_GROUP_ERRORED = 'DELETE_ORDER_GROUP_ERRORED';

export const DELETE_SELLER_ORDER_REQUESTED = 'DELETE_SELLER_ORDER_REQUESTED';
export const DELETE_SELLER_ORDER_SUCCESSFULL =
  'DELETE_SELLER_ORDER_SUCCESSFULL';
export const DELETE_SELLER_ORDER_ERRORED = 'DELETE_SELLER_ORDER_ERRORED';

export const GET_SHIPPING_ORDERS_REQUESTED = 'GET_SHIPPING_ORDERS_REQUESTED';
export const GET_SHIPPING_ORDERS_RETRIEVED = 'GET_SHIPPING_ORDERS_RETRIEVED';
export const GET_SHIPPING_ORDERS_FAILED = 'GET_SHIPPING_ORDERS_FAILED';

export const CONFIRM_ORDER_REQUESTED = 'CONFIRM_ORDER_REQUESTED';
export const CONFIRM_ORDER_SUCCESSFULL = 'CONFIRM_ORDER_SUCCESSFULL';
export const CONFIRM_ORDER_FAILED = 'CONFIRM_ORDER_FAILED';

export const CONFIRM_ORDER_GROUP_REQUESTED = 'CONFIRM_ORDER_GROUP_REQUESTED';
export const CONFIRM_ORDER_GROUP_SUCCESSFULL =
  'CONFIRM_ORDER_GROUP_SUCCESSFULL';
export const CONFIRM_ORDER_GROUP_FAILED = 'CONFIRM_ORDER_GROUP_FAILED';

export const ORDER_RECEIPT_UPLOAD_REQUESTED = 'ORDER_RECEIPT_UPLOAD_REQUESTED';
export const ORDER_RECEIPT_UPLOAD_SUCCESSFULL =
  'ORDER_RECEIPT_UPLOAD_SUCCESSFULL';
export const ORDER_RECEIPT_UPLOAD_ERRORED = 'ORDER_RECEIPT_UPLOAD_ERRORED';

export const SHIPPING_LABEL_REQUESTED = 'SHIPPING_LABEL_REQUESTED';
export const SHIPPING_LABEL_RETRIEVED = 'SHIPPING_LABEL_RETRIEVED';
export const SHIPPING_LABEL_FAILED = 'SHIPPING_LABEL_FAILED';

export const SELLER_ORDERS_REQUESTED = 'SELLER_ORDERS_REQUESTED';
export const SELLER_ORDERS_RETRIEVED = 'SELLER_ORDERS_RETRIEVED';
export const SELLER_ORDERS_FAILED = 'SELLER_ORDERS_FAILED';

export const BUYER_ORDERS_REQUESTED = 'BUYER_ORDERS_REQUESTED';
export const BUYER_ORDERS_RETRIEVED = 'BUYER_ORDERS_RETRIEVED';
export const BUYER_ORDERS_FAILED = 'BUYER_ORDERS_FAILED';

export const CREATE_SHIPPING_LABEL_REQUESTED =
  'CREATE_SHIPPING_LABEL_REQUESTED';
export const CREATE_SHIPPING_LABEL_SUCCESSFULL =
  'CREATE_SHIPPING_LABEL_SUCCESSFULL';
export const CREATE_SHIPPING_LABEL_ERRORED = 'CREATE_SHIPPING_LABEL_ERRORED';

export const TRACKING_REQUESTED = 'TRACKING_REQUESTED';
export const TRACKING_RETRIEVED = 'TRACKING_RETRIEVED';
export const TRACKING_ERRORED = 'TRACKING_ERRORED';

// Refunds
export const REFUND_REQUESTED = 'REFUND_REQUESTED';
export const REFUND_SUCCESSFULL = 'REFUND_SUCCESSFULL';
export const REFUND_ERRORED = 'REFUND_ERRORED';

export const CREATE_CHANGE_SHIPPING_REQUESTED =
  'CREATE_CHANGE_SHIPPING_REQUESTED';
export const CREATE_CHANGE_SHIPPING_SUCCESSFUL =
  'CREATE_CHANGE_SHIPPING_SUCCESSFUL';
export const CREATE_CHANGE_SHIPPING_FAILED = 'CREATE_CHANGE_SHIPPING_FAILED';

export const CHANGE_CANCELLATION_REQUESTED = 'CHANGE_CANCELLATION_REQUESTED';
export const CHANGE_CANCELLATION_SUCCESSFUL = 'CHANGE_CANCELLATION_SUCCESSFUL';
export const CHANGE_CANCELLATION_FAILED = 'CHANGE_CANCELLATION_FAILED';

// Publicity banners
export const BANNERS_REQUESTED = 'BANNERS_REQUESTED';
export const BANNERS_RETRIEVED = 'BANNERS_RETRIEVED';
export const BANNERS_FAILED = 'BANNERS_FAILED';

export const TEXT_BANNERS_REQUESTED = 'TEXT_BANNERS_REQUESTED';
export const TEXT_BANNERS_RETRIEVED = 'TEXT_BANNERS_RETRIEVED';
export const TEXT_BANNERS_FAILED = 'TEXT_BANNERS_FAILED';

// Shopping Cart
export const SHOPPING_CART_ADD_REQUESTED = 'SHOPPING_CART_ADD_REQUESTED';
export const SHOPPING_CART_ADD_SUCCESSFULL = 'SHOPPING_CART_ADD_SUCCESSFULL';
export const SHOPPING_CART_ADD_ERRORED = 'SHOPPING_CART_ADD_ERRORED';
export const SHOPPING_CART_REMOVE_REQUESTED = 'SHOPPING_CART_REMOVE_REQUESTED';
export const SHOPPING_CART_REMOVE_SUCCESSFULL =
  'SHOPPING_CART_REMOVE_SUCCESSFULL';
export const SHOPPING_CART_REMOVE_ERRORED = 'SHOPPING_CART_REMOVE_ERRORED';
export const SHOPPING_CART_REQUESTED = 'SHOPPING_CART_REQUESTED';
export const SHOPPING_CART_SUCCESSFULL = 'SHOPPING_CART_SUCCESSFULL';
export const SHOPPING_CART_ERRORED = 'SHOPPING_CART_ERRORED';

// Social feed
export const SOCIAL_FEED_REQUESTED = 'SOCIAL_FEED_REQUESTED';
export const SOCIAL_FEED_RETRIEVED = 'SOCIAL_FEED_RETRIEVED';
export const SOCIAL_FEED_FAILED = 'SOCIAL_FEED_FAILED';

export const USER_NOTIFICATIONS_REQUESTED = 'USER_NOTIFICATIONS_REQUESTED';
export const USER_NOTIFICATIONS_RETRIEVED = 'USER_NOTIFICATIONS_RETRIEVED';
export const USER_NOTIFICATIONS_FAILED = 'USER_NOTIFICATIONS_FAILED';

export const NOTIFICATION_READ_REQUESTED = 'NOTIFICATION_READ_REQUESTED';
export const NOTIFICATION_READ_SUCCESFULL = 'NOTIFICATION_READ_SUCCESFULL';
export const NOTIFICATION_READ_ERRORED = 'NOTIFICATION_READ_ERRORED';

// Financial
export const FINANCIAL_OPTIONS_REQUESTED = 'FINANCIAL_OPTIONS_REQUESTED';
export const FINANCIAL_OPTIONS_RETRIEVED = 'FINANCIAL_OPTIONS_RETRIEVED';
export const FINANCIAL_OPTIONS_FAILED = 'FINANCIAL_OPTIONS_FAILED';

export const USER_CARDS_REQUESTED = 'USER_CARDS_REQUESTED';
export const USER_CARDS_RETRIEVED = 'USER_CARDS_RETRIEVED';
export const USER_CARDS_FAILED = 'USER_CARDS_FAILED';

export const CREATE_CARD_REQUESTED = 'CREATE_CARD_REQUESTED';
export const CREATE_CARD_SUCCESSFULL = 'CREATE_CARD_SUCCESSFULL';
export const CREATE_CARD_ERRORED = 'CREATE_CARD_ERRORED';

export const DELETE_CARD_REQUESTED = 'DELETE_CARD_REQUESTED';
export const DELETE_CARD_SUCCESSFULL = 'DELETE_CARD_SUCCESSFULL';
export const DELETE_CARD_ERRORED = 'DELETE_CARD_ERRORED';

export const FINANCIAL_TOTALS_REQUESTED = 'FINANCIAL_TOTALS_REQUESTED';
export const FINANCIAL_TOTALS_RETRIEVED = 'FINANCIAL_TOTALS_RETRIEVED';
export const FINANCIAL_TOTALS_FAILED = 'FINANCIAL_TOTALS_FAILED';

export const FINANCIAL_RECORDS_REQUESTED = 'FINANCIAL_RECORDS_REQUESTED';
export const FINANCIAL_RECORDS_RETRIEVED = 'FINANCIAL_RECORDS_RETRIEVED';
export const FINANCIAL_RECORDS_FAILED = 'FINANCIAL_RECORDS_FAILED';

export const CREATE_WITHDRAWAL_REQUESTED = 'CREATE_WITHDRAWAL_REQUESTED';
export const CREATE_WITHDRAWAL_SUCCESSFULL = 'CREATE_WITHDRAWAL_SUCCESSFULL';
export const CREATE_WITHDRAWAL_ERRORED = 'CREATE_WITHDRAWAL_ERRORED';

export const BANKS_REQUESTED = 'BANKS_REQUESTED';
export const BANKS_SUCCESSFULL = 'BANKS_SUCCESSFULL';
export const BANKS_ERRORED = 'BANKS_ERRORED';

// Reviews and answers
export const CREATE_REVIEW_REQUESTED = 'CREATE_REVIEW_REQUESTED';
export const CREATE_REVIEW_SUCCESSFULL = 'CREATE_REVIEW_SUCCESSFULL';
export const CREATE_REVIEW_ERRORED = 'CREATE_REVIEW_ERRORED';

export const ANSWER_REVIEW_REQUESTED = 'ANSWER_REVIEW_REQUESTED';
export const ANSWER_REVIEW_SUCCESSFULL = 'ANSWER_REVIEW_SUCCESSFULL';
export const ANSWER_REVIEW_ERRORED = 'ANSWER_REVIEW_ERRORED';

// Discounts calculations
export const CALCULATE_DISCOUNT_REQUESTED = 'CALCULATE_DISCOUNT_REQUESTED';
export const CALCULATE_DISCOUNT_SUCCESSFULL = 'CALCULATE_DISCOUNT_SUCCESSFULL';
export const CALCULATE_DISCOUNT_ERRORED = 'CALCULATE_DISCOUNT_ERRORED';

// Promotions calculations
export const CALCULATE_PROMOTION_REQUESTED = 'CALCULATE_PROMOTION_REQUESTED';
export const CALCULATE_PROMOTION_SUCCESSFULL =
  'CALCULATE_PROMOTION_SUCCESSFULL';
export const CALCULATE_PROMOTION_ERRORED = 'CALCULATE_PROMOTION_ERRORED';

export const CAMPAIGNS_REQUESTED = 'CAMPAIGNS_REQUESTED';
export const CAMPAIGNS_RETRIEVED = 'CAMPAIGNS_RETRIEVED';
export const CAMPAIGNS_FAILED = 'CAMPAIGNS_FAILED';

export const WELCOME_BUYER_CAMPAIGN_RETRIEVED =
  'WELCOME_BUYER_CAMPAIGN_RETRIEVED';
export const WELCOME_BUYER_CAMPAIGN_FAILED = 'WELCOME_BUYER_CAMPAIGN_FAILED';
export const WELCOME_BUYER_CAMPAIGN_REQUESTED =
  'WELCOME_BUYER_CAMPAIGN_REQUESTED';

export const WELCOME_SELLER_CAMPAIGN_RETRIEVED =
  'WELCOME_SELLER_CAMPAIGN_RETRIEVED';
export const WELCOME_SELLER_CAMPAIGN_FAILED = 'WELCOME_SELLER_CAMPAIGN_FAILED';
export const WELCOME_SELLER_CAMPAIGN_REQUESTED =
  'WELCOME_SELLER_CAMPAIGN_REQUESTED';

export const FREE_SHIPPING_CAMPAIGN_RETRIEVED =
  'FREE_SHIPPING_CAMPAIGN_RETRIEVED';
export const FREE_SHIPPING_CAMPAIGN_FAILED = 'FREE_SHIPPING_CAMPAIGN_FAILED';
export const FREE_SHIPPING_CAMPAIGN_REQUESTED =
  'FREE_SHIPPING_CAMPAIGN_REQUESTED';

export const BUYER_OFFERS_REQUESTED = 'BUYER_OFFERS_REQUESTED';
export const BUYER_OFFERS_RETRIEVED = 'BUYER_OFFERS_RETRIEVED';
export const BUYER_OFFERS_ERRORED = 'BUYER_OFFERS_ERRORED';

export const USER_COUPONS_REQUESTED = 'USER_COUPONS_REQUESTED';
export const USER_COUPONS_SUCCESSFULL = 'USER_COUPONS_SUCCESSFULL';
export const USER_COUPONS_ERRORED = 'USER_COUPONS_ERRORED';

// Alerts
export const USER_ALERTS_REQUESTED = 'USER_ALERTS_REQUESTED';
export const USER_ALERTS_SUCCESSFULL = 'USER_ALERTS_SUCCESSFULL';
export const USER_ALERTS_ERRORED = 'USER_ALERTS_ERRORED';

export const CREATE_ALERT_REQUESTED = 'CREATE_ALERT_REQUESTED';
export const CREATE_ALERT_SUCCESSFULL = 'CREATE_ALERT_SUCCESSFULL';
export const CREATE_ALERT_ERRORED = 'CREATE_ALERT_ERRORED';

export const DELETE_ALERT_REQUESTED = 'DELETE_ALERT_REQUESTED';
export const DELETE_ALERT_SUCCESSFULL = 'DELETE_ALERT_SUCCESSFULL';
export const DELETE_ALERT_ERRORED = 'DELETE_ALERT_ERRORED';

export const EDIT_ALERT_REQUESTED = 'EDIT_ALERT_REQUESTED';
export const EDIT_ALERT_SUCCESSFULL = 'EDIT_ALERT_SUCCESSFULL';
export const EDIT_ALERT_ERRORED = 'EDIT_ALERT_ERRORED';

// When pending review id needs to be stored
export const PENDING_ID_STORED = 'PENDING_ID_STORED';

export const PIN_VOICE_CALL_REQUESTED = 'PIN_VOICE_CALL_REQUESTED';
export const PIN_VOICE_CALL_SUCCESSFUL = 'PIN_VOICE_CALL_SUCCESSFUL';
export const PIN_VOICE_CALL_FAILED = 'PIN_VOICE_CALL_FAILED';

// Colors
export const COLORS_REQUESTED = 'COLORS_REQUESTED';
export const COLORS_SUCCESSFULL = 'COLORS_SUCCESSFULL';
export const COLORS_ERRORED = 'COLORS_ERRORED';

// Tags
export const TAGS_REQUESTED = 'TAGS_REQUESTED';
export const TAGS_SUCCESSFULL = 'TAGS_SUCCESSFULL';
export const TAGS_ERRORED = 'TAGS_ERRORED';

export const UPDATE_PERSON_ID_REQUESTED = 'UPDATE_PERSON_ID_REQUESTED';
export const UPDATE_PERSON_ID_SUCCESSFULL = 'UPDATE_PERSON_ID_SUCCESSFULL';
export const UPDATE_PERSON_ID_ERRORED = 'UPDATE_PERSON_ID_ERRORED';

export const GET_USER_AFIP_INFO_REQUESTED = 'GET_USER_AFIP_INFO_REQUESTED';
export const GET_USER_AFIP_INFO_SUCCESSFULL = 'GET_USER_AFIP_INFO_SUCCESSFULL';
export const GET_USER_AFIP_INFO_ERRORED = 'GET_USER_AFIP_INFO_ERRORED';

export const CREATE_DEVICES_REQUESTED = 'CREATE_DEVICES_REQUESTED';
export const CREATE_DEVICES_SUCCESSFULL = 'CREATE_DEVICES_SUCCESSFULL';
export const CREATE_DEVICES_ERRORED = 'CREATE_DEVICES_ERRORED';

export const CREATE_PAYMENT_METHOD_REQUESTED =
  'CREATE_PAYMENT_METHOD_REQUESTED';
export const CREATE_PAYMENT_METHOD_SUCCESSFUL =
  'CREATE_PAYMENT_METHOD_SUCCESSFUL';
export const CREATE_PAYMENT_METHOD_ERRORED = 'CREATE_PAYMENT_METHOD_ERRORED';

export const VALIDATE_ACCOUNT_REQUESTED = 'VALIDATE_ACCOUNT_REQUESTED';
export const VALIDATE_ACCOUNT_SUCCESSFUL = 'VALIDATE_ACCOUNT_SUCCESSFUL';
export const VALIDATE_ACCOUNT_ERRORED = 'VALIDATE_ACCOUNT_ERRORED';

export const CHANGE_MASSIVE_PRODUCTS_PRICE_REQUESTED =
  'CHANGE_MASSIVE_PRODUCTS_PRICE_REQUESTED';
export const CHANGE_MASSIVE_PRODUCTS_PRICE_SUCCESSFULL =
  'CHANGE_MASSIVE_PRODUCTS_PRICE_SUCCESSFUL';
export const CHANGE_MASSIVE_PRODUCTS_PRICE_ERRORED =
  'CHANGE_MASSIVE_PRODUCTS_PRICE_ERRORED';

export const CHANGE_MASSIVE_PRODUCTS_SKU_REQUESTED =
  'CHANGE_MASSIVE_PRODUCTS_SKU_REQUESTED';
export const CHANGE_MASSIVE_PRODUCTS_SKU_SUCCESSFUL =
  'CHANGE_MASSIVE_PRODUCTS_SKU_SUCCESSFUL';
export const CHANGE_MASSIVE_PRODUCTS_SKU_ERRORED =
  'CHANGE_MASSIVE_PRODUCTS_SKU_ERRORED';

export const CREATE_USER_PROFILES_REQUESTED = 'CREATE_USER_PROFILES_REQUESTED';
export const CREATE_USER_PROFILES_SUCCESSFUL =
  'CREATE_USER_PROFILES_SUCCESSFUL';
export const CREATE_USER_PROFILES_ERRORED = 'CREATE_USER_PROFILES_ERRORED';

export const GET_USER_PROFILES_REQUESTED = 'GET_USER_PROFILES_REQUESTED';
export const GET_USER_PROFILES_SUCCESSFUL = 'GET_USER_PROFILES_SUCCESSFUL';
export const GET_USER_PROFILES_ERRORED = 'GET_USER_PROFILES_ERRORED';

export const PERSONAL_INFO_POPUP_CLOSED = 'PERSONAL_INFO_POPUP_CLOSED';
export const CHECK_MERCADOPAGO_REQUESTED = 'CHECK_MERCADOPAGO_REQUESTED';
export const CHECK_MERCADOPAGO_SUCCESSFULL = 'CHECK_MERCADOPAGO_SUCCESSFULL';
export const CHECK_MERCADOPAGO_ERRORED = 'CHECK_MERCADOPAGO_ERRORED';

export const GET_PAYMENT_METHODS_REQUESTED = 'GET_PAYMENT_METHODS_REQUESTED';
export const GET_PAYMENT_METHODS_SUCCESSFUL = 'GET_PAYMENT_METHODS_SUCCESSFUL';
export const GET_PAYMENT_METHODS_ERRORED = 'GET_PAYMENT_METHODS_ERRORED';

export const DOWNLOAD_APP_BANNER_CLOSED = 'DOWNLOAD_APP_BANNER_CLOSED';

export const OPEN_SHOPPING_CART_PREVIEW = 'OPEN_SHOPPING_CART_PREVIEW';
export const CLOSE_SHOPPING_CART_PREVIEW = 'CLOSE_SHOPPING_CART_PREVIEW';

export const CLEAN_SHOPPING_CART_REQUESTED = 'CLEAN_SHOPPING_CART_REQUESTED';
export const CLEAN_SHOPPING_CART_SUCCESSFULL =
  'CLEAN_SHOPPING_CART_SUCCESSFULL';
export const CLEAN_SHOPPING_CART_ERRORED = 'CLEAN_SHOPPING_CART_ERRORED';

export const CREATE_RESELLER_REQUESTED = 'CREATE_RESELLER_REQUESTED';
export const CREATE_RESELLER_SUCCESSFULL = 'CREATE_RESELLER_SUCCESSFULL';
export const CREATE_RESELLER_ERRORED = 'CREATE_RESELLER_ERRORED';

export const CREATE_SUPPLIER_REQUESTED = 'CREATE_SUPPLIER_REQUESTED';
export const CREATE_SUPPLIER_SUCCESSFULL = 'CREATE_SUPPLIER_SUCCESSFULL';
export const CREATE_SUPPLIER_NO_RESELLERS_SUCCESSFULL =
  'CREATE_SUPPLIER_NO_RESELLERS_SUCCESSFULL';
export const CREATE_SUPPLIER_ERRORED = 'CREATE_SUPPLIER_ERRORED';

export const CHECK_RESELLER_ELIGIBILITY_REQUESTED =
  'CHECK_RESELLER_ELIGIBILITY_REQUESTED';
export const CHECK_RESELLER_ELIGIBILITY_SUCCESSFULL =
  'CHECK_RESELLER_ELIGIBILITY_SUCCESSFULL';
export const CHECK_RESELLER_ELIGIBILITY_ERRORED =
  'CHECK_RESELLER_ELIGIBILITY_ERRORED';

export const UPDATE_PARTNER_REGIONS_REQUESTED =
  'UPDATE_PARTNER_REGIONS_REQUESTED';
export const UPDATE_PARTNER_REGIONS_SUCCESSFULL =
  'UPDATE_PARTNER_REGIONS_SUCCESSFULL';
export const UPDATE_PARTNER_REGIONS_ERRORED = 'UPDATE_PARTNER_REGIONS_ERRORED';

export const GET_PARTNER_RELATIONS_REQUESTED =
  'GET_PARTNER_RELATIONS_REQUESTED';
export const GET_PARTNER_RELATIONS_SUCCESSFULL =
  'GET_PARTNER_RELATIONS_SUCCESSFULL';
export const GET_PARTNER_RELATIONS_ERRORED = 'GET_PARTNER_RELATIONS_ERRORED';

export const GET_PAGINATED_MESSAGES_REQUESTED =
  'GET_PAGINATED_MESSAGES_REQUESTED';
export const GET_PAGINATED_MESSAGES_SUCCESSFULL =
  'GET_PAGINATED_MESSAGES_SUCCESSFULL';
export const GET_PAGINATED_MESSAGES_ERRORED = 'GET_PAGINATED_MESSAGES_ERRORED';

export const GET_LATEST_MESSAGES_REQUESTED = 'GET_LATEST_MESSAGES_REQUESTED';
export const GET_LATEST_MESSAGES_SUCCESSFULL =
  'GET_LATEST_MESSAGES_SUCCESSFULL';
export const GET_LATEST_MESSAGES_ERRORED = 'GET_LATEST_MESSAGES_ERRORED';

export const GET_LAST_MESSAGE_DATE_REQUESTED =
  'GET_LAST_MESSAGE_DATE_REQUESTED';
export const GET_LAST_MESSAGE_DATE_SUCCESSFULL =
  'GET_LAST_MESSAGE_DATE_SUCCESSFULL';
export const GET_LAST_MESSAGE_DATE_ERRORED = 'GET_LAST_MESSAGE_DATE_ERRORED';

export const POST_PARTNER_MESSAGE_REQUESTED = 'POST_PARTNER_MESSAGE_REQUESTED';
export const POST_PARTNER_MESSAGE_SUCCESSFULL =
  'POST_PARTNER_MESSAGE_SUCCESSFULL';
export const POST_PARTNER_MESSAGE_ERRORED = 'POST_PARTNER_MESSAGE_ERRORED';

export const SIGN_CONTRACT_REQUESTED = 'SIGN_CONTRACT_REQUESTED';
export const SIGN_CONTRACT_SUCCESSFULL = 'SIGN_CONTRACT_SUCCESSFULL';
export const SIGN_CONTRACT_ERRORED = 'SIGN_CONTRACT_ERRORED';

export const PARTNER_NOTICE_DISPATCH_REQUESTED =
  'PARTNER_NOTICE_DISPATCH_REQUESTED';
export const PARTNER_NOTICE_DISPATCH_SUCCESSFULL =
  'PARTNER_NOTICE_DISPATCH_SUCCESSFULL';
export const PARTNER_NOTICE_DISPATCH_ERRORED =
  'PARTNER_NOTICE_DISPATCH_ERRORED';

export const PARTNER_NOTICE_PROPOSAL_REQUESTED =
  'PARTNER_NOTICE_PROPOSAL_REQUESTED';
export const PARTNER_NOTICE_PROPOSAL_SUCCESSFULL =
  'PARTNER_NOTICE_PROPOSAL_SUCCESSFULL';
export const PARTNER_NOTICE_PROPOSAL_ERRORED =
  'PARTNER_NOTICE_PROPOSAL_ERRORED';

export const PARTNER_NOTICE_APPROVE_REQUESTED =
  'PARTNER_NOTICE_APPROVE_REQUESTED';
export const PARTNER_NOTICE_APPROVE_SUCCESSFULL =
  'PARTNER_NOTICE_APPROVE_SUCCESSFULL';
export const PARTNER_NOTICE_APPROVE_ERRORED = 'PARTNER_NOTICE_APPROVE_ERRORED';

export const PARTNER_NOTICE_REJECT_REQUESTED =
  'PARTNER_NOTICE_REJECT_REQUESTED';
export const PARTNER_NOTICE_REJECT_SUCCESSFULL =
  'PARTNER_NOTICE_REJECT_SUCCESSFULL';
export const PARTNER_NOTICE_REJECT_ERRORED = 'PARTNER_NOTICE_REJECT_ERRORED';

export const GET_SETTINGS_REQUESTED = 'GET_SETTINGS_REQUESTED';
export const GET_SETTINGS_SUCCESFULL = 'GET_SETTINGS_SUCCESFULL';
export const GET_SETTINGS_ERRORED = 'GET_SETTINGS_ERRORED';

export const OPEN_RELATION_DISPUTE_REQUESTED =
  'OPEN_RELATION_DISPUTE_REQUESTED';
export const OPEN_RELATION_DISPUTE_SUCCESSFULL =
  'OPEN_RELATION_DISPUTE_SUCCESSFULL';
export const OPEN_RELATION_DISPUTE_ERRORED = 'OPEN_RELATION_DISPUTE_ERRORED';

export const CHANGE_RESELLER_REQUESTED = 'CHANGE_RESELLER_REQUESTED';
export const CHANGE_RESELLER_SUCCESSFULL = 'CHANGE_RESELLER_SUCCESSFULL';
export const CHANGE_RESELLER_ERRORED = 'CHANGE_RESELLER_ERRORED';

export const SUPPLIER_DROPOUT_REQUESTED = 'SUPPLIER_DROPOUT_REQUESTED';
export const SUPPLIER_DROPOUT_SUCCESSFULL = 'SUPPLIER_DROPOUT_SUCCESSFULL';
export const SUPPLIER_DROPOUT_ERRORED = 'SUPPLIER_DROPOUT_ERRORED';

export const RESELLER_DROPOUT_REQUESTED = 'RESELLER_DROPOUT_REQUESTED';
export const RESELLER_DROPOUT_SUCCESSFULL = 'RESELLER_DROPOUT_SUCCESSFULL';
export const RESELLER_DROPOUT_ERRORED = 'RESELLER_DROPOUT_ERRORED';

export const OPEN_PARTNER_DISPUTE_REQUESTED = 'OPEN_PARTNER_DISPUTE_REQUESTED';
export const OPEN_PARTNER_DISPUTE_SUCCESSFULL =
  'OPEN_PARTNER_DISPUTE_SUCCESSFULL';
export const OPEN_PARTNER_DISPUTE_ERRORED = 'OPEN_PARTNER_DISPUTE_ERRORED';

export const APPROVE_SUPPLIER_REQUESTED = 'APPROVE_SUPPLIER_REQUESTED';
export const APPROVE_SUPPLIER_SUCCESSFULL = 'APPROVE_SUPPLIER_SUCCESSFULL';
export const APPROVE_SUPPLIER_ERRORED = 'APPROVE_SUPPLIER_ERRORED';

export const REJECT_SUPPLIER_REQUESTED = 'REJECT_SUPPLIER_REQUESTED';
export const REJECT_SUPPLIER_SUCCESSFUL = 'REJECT_SUPPLIER_SUCCESSFUL';
export const REJECT_SUPPLIER_ERRORED = 'REJECT_SUPPLIER_ERRORED';

export const PAUSE_RESELLER_REQUESTED = 'PAUSE_RESELLER_REQUESTED';
export const PAUSE_RESELLER_SUCCESSFUL = 'PAUSE_RESELLER_SUCCESSFUL';
export const PAUSE_RESELLER_ERRORED = 'PAUSE_RESELLER_ERRORED';

export const UNPAUSE_RESELLER_REQUESTED = 'UNPAUSE_RESELLER_REQUESTED';
export const UNPAUSE_RESELLER_SUCCESSFUL = 'UNPAUSE_RESELLER_SUCCESSFUL';
export const UNPAUSE_RESELLER_ERRORED = 'UNPAUSE_RESELLER_ERRORED';

export const END_RELATION_REQUESTED = 'END_RELATION_REQUESTED';
export const END_RELATION_SUCCESSFUL = 'END_RELATION_SUCCESSFUL';
export const END_RELATION_ERRORED = 'END_RELATION_ERRORED';

export const GET_PARTNER_REQUESTED = 'GET_PARTNER_REQUESTED';
export const GET_PARTNER_SUCCESSFUL = 'GET_PARTNER_SUCCESSFUL';
export const GET_PARTNER_ERRORED = 'GET_PARTNER_ERRORED';

export const GET_PARTNER_SKUS_REQUESTED = 'GET_PARTNER_SKUS_REQUESTED';
export const GET_PARTNER_SKUS_SUCCESSFUL = 'GET_PARTNER_SKUS_SUCCESSFUL';
export const GET_PARTNER_SKUS_ERRORED = 'GET_PARTNER_SKUS_ERRORED';

export const GET_PARTNER_TRAINING_REQUESTED = 'GET_PARTNER_TRAINING_REQUESTED';
export const GET_PARTNER_TRAINING_SUCCESSFUL =
  'GET_PARTNER_TRAINING_SUCCESSFUL';
export const GET_PARTNER_TRAINING_ERRORED = 'GET_PARTNER_TRAINING_ERRORED';

export const UPDATE_PARTNER_TRAINING_REQUESTED =
  'UPDATE_PARTNER_TRAINING_REQUESTED';
export const UPDATE_PARTNER_TRAINING_SUCCESSFUL =
  'UPDATE_PARTNER_TRAINING_SUCCESSFUL';
export const UPDATE_PARTNER_TRAINING_ERRORED =
  'UPDATE_PARTNER_TRAINING_ERRORED';

export const GET_QUESTIONS_TRAINING_REQUESTED =
  'GET_QUESTIONS_TRAINING_REQUESTED';
export const GET_QUESTIONS_TRAINING_SUCCESSFUL =
  'GET_QUESTIONS_TRAINING_SUCCESSFUL';
export const GET_QUESTIONS_TRAINING_ERRORED = 'GET_QUESTIONS_TRAINING_ERRORED';

export const POST_QUESTIONS_TRAINING_REQUESTED =
  'POST_QUESTIONS_TRAINING_REQUESTED';
export const POST_QUESTIONS_TRAINING_SUCCESSFUL =
  'POST_QUESTIONS_TRAINING_SUCCESSFUL';
export const POST_QUESTIONS_TRAINING_ERRORED =
  'POST_QUESTIONS_TRAINING_ERRORED';

export const CHECK_USER_BLOCKED_REQUESTED = 'CHECK_USER_BLOCKED_REQUESTED';
export const CHECK_USER_BLOCKED_SUCCESSFULL = 'CHECK_USER_BLOCKED_SUCCESSFULL';
export const CHECK_USER_BLOCKED_ERRORED = 'CHECK_USER_BLOCKED_ERRORED';

export const OPEN_BLOCKED_USER_ALERT = 'OPEN_BLOCKED_USER_ALERT';

export const SEND_PRODUCT_EVENT_ANALYTICS_REQUESTED =
  'SEND_PRODUCT_EVENT_ANALYTICS_REQUESTED';
export const SEND_PRODUCT_EVENT_ANALYTICS_SUCCESSFULL =
  'SEND_PRODUCT_EVENT_ANALYTICS_SUCCESSFULL';
export const SEND_PRODUCT_EVENT_ANALYTICS_ERRORED =
  'SEND_PRODUCT_EVENT_ANALYTICS_ERRORED';

export const GET_PARNET_RESELLER_SALES_REQUESTED =
  'GET_PARNET_RESELLER_SALES_REQUESTED';
export const GET_PARNET_RESELLER_SALES_SUCCESSFUL =
  'GET_PARNET_RESELLER_SALES_SUCCESSFUL';
export const GET_PARNET_RESELLER_SALES_ERRORED =
  'GET_PARNET_RESELLER_SALES_ERRORED';

export const GET_PARNET_RESELLER_SALE_DETAILS_REQUESTED =
  'GET_PARNET_RESELLER_SALE_DETAILS_REQUESTED';
export const GET_PARNET_RESELLER_SALE_DETAILS_SUCCESSFUL =
  'GET_PARNET_RESELLER_SALE_DETAILS_SUCCESSFUL';
export const GET_PARNET_RESELLER_SALE_DETAILS_ERRORED =
  'GET_PARNET_RESELLER_SALE_DETAILS_ERRORED';

export const OPEN_RESELLER_SALE_DETAILS_MODAL =
  'OPEN_RESELLER_SALE_DETAILS_MODAL';

export const GET_SUPPLIER_SALES_REQUESTED = 'GET_SUPPLIER_SALES_REQUESTED';
export const GET_SUPPLIER_SALES_SUCCESSFUL = 'GET_SUPPLIER_SALES_SUCCESSFUL';
export const GET_SUPPLIER_SALES_ERRORED = 'GET_SUPPLIER_SALES_ERRORED';

export const UPDATE_BY_PRIVATE_STATS = 'UPDATE_BY_PRIVATE_STATS';

export const SHOW_TOAST = 'SHOW_TOAST';
export const REMOVE_TOAST = 'REMOVE_TOAST';

export const HIDE_INFO_CHECKING_SHOPPINGCART_MODAL =
  'HIDE_INFO_CHECKING_SHOPPINGCART_MODAL';
