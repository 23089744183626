import {
  GET_SETTINGS_REQUESTED,
  GET_SETTINGS_SUCCESFULL,
  GET_SETTINGS_ERRORED,
  HIDE_INFO_CHECKING_SHOPPINGCART_MODAL,
  SHOW_TOAST,
  REMOVE_TOAST
} from '../actions/types';

const defaultState = {
  status: '',
  loading: false,
  metadata: null,
  toastQueue: [],
  hiddenInfoCheckingShoppingcartModal: false
};

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case GET_SETTINGS_REQUESTED: {
      return {
        ...state,
        loading: true,
        status: GET_SETTINGS_REQUESTED
      };
    }
    case GET_SETTINGS_SUCCESFULL: {
      return {
        ...state,
        loading: false,
        status: GET_SETTINGS_SUCCESFULL,
        metadata: action.metadata
      };
    }
    case GET_SETTINGS_ERRORED: {
      return {
        ...state,
        loading: false,
        status: GET_SETTINGS_ERRORED
      };
    }
    case HIDE_INFO_CHECKING_SHOPPINGCART_MODAL: {
      return {
        ...state,
        loading: false,
        status: HIDE_INFO_CHECKING_SHOPPINGCART_MODAL,
        hiddenInfoCheckingShoppingcartModal: action.hide
      };
    }
    case SHOW_TOAST: {
      return {
        ...state,
        status: SHOW_TOAST,
        toastQueue: [...state.toastQueue, action.config]
      };
    }
    case REMOVE_TOAST:
      return {
        ...state,
        status: REMOVE_TOAST,
        toastQueue: state.toastQueue.slice(1)
      };
    default:
      return state;
  }
}
