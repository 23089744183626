import Settings from '../../http/client/Settings';
import {
  GET_SETTINGS_REQUESTED,
  GET_SETTINGS_SUCCESFULL,
  GET_SETTINGS_ERRORED,
  HIDE_INFO_CHECKING_SHOPPINGCART_MODAL,
  SHOW_TOAST,
  REMOVE_TOAST
} from './types';

export const getSettings = () => {
  const requested = () => ({
    type: GET_SETTINGS_REQUESTED
  });

  const successfull = response => ({
    type: GET_SETTINGS_SUCCESFULL,
    metadata: response.data
  });

  const errored = error => ({
    type: GET_SETTINGS_ERRORED,
    error
  });

  return dispatch => {
    dispatch(requested());

    return Settings.getSettings()
      .then(response => dispatch(successfull(response)))
      .catch(err => dispatch(errored(err.response)));
  };
};

export const showToast = config => ({
  type: SHOW_TOAST,
  config
});

export const removeToast = () => ({
  type: REMOVE_TOAST
});

export const hideInfoCheckingShoppingcartModal = () => ({
  type: HIDE_INFO_CHECKING_SHOPPINGCART_MODAL,
  hide: true
});
